import React, { useState, Fragment, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createNewForum } from '../../../application/slices/forum.slice'
import BreadCrumbs from '../../../components/breadcrumbs'

const AddForum = () => {
  const dispatch = useDispatch()
  const hiddenFileInput = useRef(null)
  const history = useHistory()
  const [forum, setForum] = useState({})
  const [forumImage, setforumImage] = useState({
    preview: '',
    file: '',
  })

  const handleForumChanges = (event) => {
    setForum({
      ...forum,
      [event.target.name]: event.target.value,
    })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const closeprofilePicturePreview = (event) => {
    event.preventDefault()
    setforumImage({
      preview: '',
      file: null,
    })
  }

  const handleChange = (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setforumImage({
          preview: URL.createObjectURL(singleImage),
          file: singleImage,
        })
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const savedHandler = (event) => {
    event.preventDefault()
    let formData = new FormData()
    let forumInfo = { ...forum }

    let forumDataString = JSON.stringify(forumInfo)
    formData.append('forum', forumDataString)
    if (forum.file) {
      formData.append('image', forum.file)
    }

    dispatch(createNewForum(formData, 'createNewForum')).then((data) => {
      if (data.error) {
        window.alert('forum saving error')
      } else {
        history.push('/forums')
      }
    })
  }

  return (
    <div className="body d-flex py-3">
      <section className="border border-light">
        <div className="container-xxl">
          <div className="row align-items-center">
            <BreadCrumbs
              selectedTabName="Forum"
              selectedTabUrl="forums"
              activePage="New"
            />
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Add Forum</h3>
                <button
                  type="submit"
                  className="btn btn-primary py-2 px-5 text-uppercase btn-set-task w-sm-100"
                  onClick={savedHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Forum Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6">
                      <label className="form-label">Form Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={forum?.title}
                        onChange={handleForumChanges}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Forum Subtitle</label>
                      <input
                        type="text"
                        className="form-control"
                        name="subTitle"
                        value={forum?.subTitle}
                        onChange={handleForumChanges}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-lg-top">
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Form Category</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        value="general"
                        checked={forum.category === 'general'}
                        onChange={handleForumChanges}
                      />
                      <label className="form-check-label">General</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        value="dog"
                        checked={forum.category === 'dog'}
                        onChange={handleForumChanges}
                      />
                      <label className="form-check-label">Dog</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="category"
                        value="cat"
                        checked={forum.category === 'cat'}
                        onChange={handleForumChanges}
                      />
                      <label className="form-check-label">Cat</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="card-header py-3 bg-transparent border-bottom-0">
                  <h6 className="m-0 fw-bold white">Image</h6>
                </div>
                <div className="card-body">
                  {forumImage.preview ? (
                    <div className="fileinput text-center">
                      <div className="image-preview">
                        {forumImage.preview && (
                          <Fragment>
                            <div
                              className="post-image"
                              style={{
                                backgroundImage: `url(${forumImage.preview})`,
                              }}
                            />
                            <i
                              onClick={closeprofilePicturePreview}
                              className="material-icons cursor-pointer"
                            >
                              x
                            </i>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="thumbnail" onClick={handleClick}>
                      <input
                        className="file"
                        type="file"
                        name="image"
                        multiple
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        accept="image/*"
                      />
                      <button
                        type="button"
                        className="btn-simple btn btn-warning"
                      >
                        Select image
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AddForum
