/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import dayjs from 'dayjs'
import { Link, useParams, useHistory } from 'react-router-dom'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useSelector, useDispatch } from 'react-redux'
import BreadCrumbs from '../../../components/breadcrumbs'
import {
  fetchAsyncOrder,
  orderSelector,
  updateOrderStatus,
} from '../../../application/slices/order.slice'
import { calculateOverallPrice } from '../../../utils'
dayjs.extend(localizedFormat)

const OrderDetails = () => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderDetail } = useSelector(orderSelector)
  const [status, setStatus] = React.useState('')

  React.useEffect(() => {
    dispatch(fetchAsyncOrder(id)).then((order) => {
      setStatus(order?.payload?.orderStatus)
    })
  }, [dispatch, id])

  const createdOn = dayjs(orderDetail?.createdAt).format('lll')
  const overallPrice = calculateOverallPrice({
    cartPrice: orderDetail?.totalAmount,
    isCOD: orderDetail?.paymentMode === 'Cash On Delivery',
    couponAmount:
      orderDetail?.couponId && orderDetail?.couponAmount
        ? orderDetail.couponAmount
        : 0,
  })

  const onChangeStatus = (event) => setStatus(event.target.value)
  const updateStatusHandler = (event) => {
    event.preventDefault()

    dispatch(
      updateOrderStatus({
        id,
        status,
        orderId: orderDetail?.orderId,
        name: orderDetail?.customerinfo?.fullName,
        email: orderDetail?.customerinfo?.email,
      })
    )
      .then(() => {
        history.push('/orders')
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Orders"
          selectedTabUrl="orders"
          activePage={orderDetail?.orderId}
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">
                ID: #{orderDetail?.orderId}
              </h3>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
          <div className="col">
            <div className="alert-success alert mb-0">
              <div className="d-flex align-items-center">
                <div className="avatar rounded no-thumbnail bg-success text-light">
                  <i
                    className="fa fa-shopping-cart fa-lg"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="flex-fill ms-3 text-truncate">
                  <div className="h6 mb-0">Order Created on</div>
                  <span className="small">{createdOn}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="alert-danger alert mb-0">
              <div className="d-flex align-items-center">
                <div className="avatar rounded no-thumbnail bg-danger text-light">
                  <i className="fa fa-user fa-lg" aria-hidden="true"></i>
                </div>
                <div className="flex-fill ms-3 text-truncate">
                  <div className="h6 mb-0">Name</div>
                  <span className="small">
                    {orderDetail?.customerinfo?.fullName}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="alert-warning alert mb-0">
              <div className="d-flex align-items-center">
                <div className="avatar rounded no-thumbnail bg-warning text-light">
                  <i className="fa fa-envelope fa-lg" aria-hidden="true"></i>
                </div>
                <div className="flex-fill ms-3 text-truncate">
                  <div className="h6 mb-0">Email</div>
                  <span className="small">
                    {' '}
                    {orderDetail?.customerinfo?.email}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="alert-info alert mb-0">
              <div className="d-flex align-items-center">
                <div className="avatar rounded no-thumbnail bg-info text-light">
                  <i
                    className="fa fa-phone-square fa-lg"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="flex-fill ms-3 text-truncate">
                  <div className="h6 mb-0">Contact No</div>
                  <span className="small">
                    {orderDetail?.customerinfo?.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3 row-cols-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-deck">
          <div className="col">
            <div className="card auth-detailblock">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Delivery Address</h6>
                <Link to="#" className="text-muted">
                  Edit
                </Link>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">
                      Address:
                    </label>
                    <p>
                      <strong>{orderDetail?.customerinfo?.address}</strong>
                    </p>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">City:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.city}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">State:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.stateName}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">
                      Pincode:
                    </label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.postalCode}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">Phone:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.phoneNumber}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Billing Address</h6>
                <Link to="#" className="text-muted">
                  Edit
                </Link>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">
                      Address:
                    </label>
                    <p>
                      <strong>{orderDetail?.customerinfo?.address}</strong>
                    </p>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">City:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.city}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">State:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.stateName}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">
                      Pincode:
                    </label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.postalCode}</strong>
                    </span>
                  </div>
                  <div className="col-12">
                    <label className="form-label col-6 col-sm-5">Phone:</label>
                    <span>
                      <strong>{orderDetail?.customerinfo?.phoneNumber}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Invoice Detail</h6>
                <Link to="#" className="text-muted">
                  Download
                </Link>
              </div>
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-12 fx_jb">
                    <label className="form-label col-6 col-sm-5">
                      OrderId:
                    </label>
                    <span>
                      <strong>#{orderDetail?.orderId}</strong>
                    </span>
                  </div>
                  <div className="col-12 fx_jb">
                    <label className="form-label col-6 col-sm-5">Status:</label>
                    <span>
                      <strong>{orderDetail?.orderStatus}</strong>
                    </span>
                  </div>
                  <div className="col-12 fx_jb">
                    <label className="form-label col-6 col-sm-5">
                      Payment:
                    </label>
                    <span>
                      <strong>{orderDetail.paymentMode}</strong>
                    </span>
                  </div>
                  <div className="col-12 fx_jb">
                    <label className="form-label col-6 col-sm-5">
                      Coupon Amount:
                    </label>
                    <span>
                      <strong>₹ {orderDetail?.couponAmount || '-'}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-xl-12 col-xxl-4">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Update Order Status</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <div className="col-md-12">
                    <label className="form-label">Order ID</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={orderDetail?.orderId}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Order Status</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={status}
                      onChange={onChangeStatus}
                    >
                      <option value="confirmed">Confirmed</option>
                      <option value="shipped">Shipped</option>
                      <option value="delivered">Delivered</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>
                  {/* <div className="col-md-12">
                      <label className="form-label">Quantity</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="4"
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Order Transection</label>
                      <select className="form-select" aria-label="Transection">
                        <option defaultValue="1">Completed</option>
                        <option defaultValue="2">Fail</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="comment" className="form-label">
                        Comment
                      </label>
                      <textarea className="form-control" id="comment" rows="4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </textarea>
                    </div> */}
                </div>
                <button
                  type="button"
                  onClick={updateStatusHandler}
                  className="btn btn-primary mt-4 text-uppercase"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-8">
            <div className="card">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Order Summary</h6>
              </div>
              <div className="card-body">
                <div className="product-cart">
                  <div className="checkout-table table-responsive">
                    <table
                      id="myCartTable"
                      className="table display dataTable table-hover align-middle"
                      style={{ width: '100%' }}
                    >
                      <thead>
                        <tr>
                          <th>Product Image</th>
                          <th className="product">Product Name</th>
                          <th className="quantity">Quantity</th>
                          <th className="price">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetail?.order && orderDetail.order.length > 0
                          ? orderDetail.order.map((product) => (
                              <tr key={product.productId}>
                                <td>
                                  <img
                                    src={product.productImages[0]}
                                    className="avatar rounded lg"
                                    alt="Product"
                                  />
                                </td>
                                <td>
                                  <h6 className="title">
                                    {product.productName}{' '}
                                  </h6>
                                </td>
                                <td>{product.cartQuantity}</td>
                                <td>
                                  <p className="price">₹ {product.price}</p>
                                </td>
                              </tr>
                            ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                  <div className="checkout-coupon-total checkout-coupon-total-2 d-flex flex-wrap justify-content-end mt-2">
                    <div className="checkout-total">
                      <div className="single-total">
                        <p className="defaultValue">Subotal Price:</p>
                        <p className="price">₹ {overallPrice}</p>
                      </div>
                      <div className="single-total">
                        <p className="defaultValue">Shipping Cost (+):</p>
                        <p className="price">
                          ₹ {overallPrice > 1000 ? '0' : 49}
                        </p>
                      </div>
                      <div className="single-total">
                        <p className="defaultValue">Discount (-):</p>
                        <p className="price">
                          ₹ {orderDetail?.couponAmount || 0}
                        </p>
                      </div>
                      {orderDetail?.paymentMode === 'Cash On Delivery' && (
                        <div className="single-total">
                          <p className="defaultValue">COD Charges:</p>
                          <p className="price">₹ 39</p>
                        </div>
                      )}
                      <div className="single-total total-payable">
                        <p className="defaultValue">Total Payable:</p>
                        <p className="price">₹ {orderDetail.totalAmount}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row g-3 mb-3">
          <div className="card p-xl-5 p-lg-4 p-0">
            <div className="card-body">
              <div className="mb-3 pb-3 border-bottom">
                Invoice
                <strong>May 22, 2021</strong>
                <span className="float-end">
                  {' '}
                  <strong>transection id:</strong> #18414
                </span>
              </div>

              <div className="row mb-4">
                <div className="col-sm-6">
                  <h6 className="mb-3">From:</h6>
                  <div>
                    <strong>eBazar Shop</strong>
                  </div>
                  <div>111 Berkeley Rd</div>
                  <div>STREET ON THE FOSSE, Poland</div>
                  <div>Email: info@ebazar.com</div>
                  <div>Phone: +44 888 666 3333</div>
                </div>

                <div className="col-sm-6">
                  <h6 className="mb-3">To:</h6>
                  <div>
                    <strong>Dianalove</strong>
                  </div>
                  <div>45 Larissa Court</div>
                  <div>Victoria, BIRDWOODTON</div>
                  <div>Email: Dianalove@gmail.com</div>
                  <div>Phone: +66 243 456 789</div>
                </div>
              </div>

              <div className="table-responsive-sm">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Item</th>
                      <th>Description</th>
                      <th className="text-end">Item Cost</th>
                      <th className="text-center">Products Item</th>
                      <th className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">1</td>
                      <td>Rado Watch</td>
                      <td>Men Watch for Gold Color</td>
                      <td className="text-end">$330.00</td>
                      <td className="text-center">1</td>
                      <td className="text-end">$330.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="row">
                <div className="col-lg-4 col-sm-5"></div>

                <div className="col-lg-4 col-sm-5 ms-auto">
                  <table className="table table-clear">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Subtotal</strong>
                        </td>
                        <td className="text-end">$330.00</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Tax(18%)</strong>
                        </td>
                        <td className="text-end">$59.4</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td className="text-end">
                          <strong>$389.00</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <h6>Terms &amp; Condition</h6>
                  <p className="text-muted">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over
                  </p>
                </div>
                <div className="col-lg-12 text-end">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-lg my-1"
                  >
                    <i className="fa fa-print"></i> Print
                  </button>
                  <button type="button" className="btn btn-primary btn-lg my-1">
                    <i className="fa fa-paper-plane-o"></i> Send Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default OrderDetails
