/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, Fragment, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateService } from '../../../application/slices/service.slice'
import BreadCrumbs from '../../../components/breadcrumbs'
import RichTextEditor from '../../../components/richTextEditor'
import FaqSection from '../../../components/faqSection'
import { fetchAsyncService } from '../../../application/slices/service.slice'
import '../services.scss'

const EditService = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const hiddenMutipleFileInput = useRef(null)
  const hiddenFileInput = useRef(null)

  const [isDisabled, setIsDisabled] = useState(false)
  const [services, setServices] = useState({
    name: '',
    description: '',
    faqs: [],
    pricings: [],
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
    seoContent: '',
    status: 'published',
    serviceType: 'General',
    petTypes: ['Dog'],
    slug: '',
    serviceIndex: null,
    isVetService: false,
  })

  useEffect(() => {
    dispatch(fetchAsyncService(id), 'fetchAsyncService').then((data) => {
      setServices(data.payload)
      setServicePricings(data.payload.pricings)
      setFaqContents(data.payload.faqs)
      if (data?.payload?.image) {
        setCoverImg({
          imagePreview: data.payload.image,
          fileSelected: data.payload.image,
        })
      }
    })
  }, [dispatch, id])

  const [coverImg, setCoverImg] = useState({
    imagePreview: '',
    fileSelected: null,
  })

  const [galleryImages, setGalleryImages] = useState({
    galleryPreview: [],
    selectedFiles: [],
  })

  const [servicePricings, setServicePricings] = useState([
    {
      heading: '',
      price: '',
      description: '',
    },
  ])

  const [faqContents, setFaqContents] = useState([
    {
      heading: '',
      description: '',
    },
  ])

  const handleInputChanges = (e) => {
    const { name, value } = e.target
    let serviceObject = { ...services }

    if (name === 'name') {
      const titleSlug = value
        ? value.toLowerCase().replace(/&'/g, '').split(' ').join('-')
        : ''
      serviceObject.name = value
      serviceObject.slug = titleSlug
    } else if (name === 'serviceIndex') {
      serviceObject = { ...serviceObject, [name]: Number(value) }
    } else {
      serviceObject = { ...serviceObject, [name]: value }
    }

    setServices(serviceObject)
  }

  const handleStatus = (e) => {
    services.status === 'hidden'
      ? setServices({ ...services, [e.target.name]: 'published' })
      : setServices({ ...services, [e.target.name]: 'hidden' })
  }

  const handleServiceTypes = (typeName) => {
    setServices({ ...services, serviceType: typeName })
  }

  const handleGalleryImgClick = () => hiddenMutipleFileInput.current.click()

  const handleGalleryImgChange = (event) => {
    const images = event.target.files
    const { galleryPreview, selectedFiles } = images

    let previewImg = [...galleryPreview],
      imgFiles = [...selectedFiles]

    if (images && !!images.length) {
      for (let i = 0; i < images.length; i++) {
        let singleImage = images[i]
        if (singleImage.size < 5242880) {
          previewImg.push(URL.createObjectURL(singleImage))
          imgFiles.push(singleImage)
        } else {
          alert(`${singleImage.name} is greater than 5MB`)
        }
      }

      setGalleryImages({
        galleryPreview: previewImg,
        selectedFiles: imgFiles,
      })
    }
    // this line right below will reset the
    // input field so if you removed it you can re-add the same file
    event.target.value = ''
  }

  const closeGalleryPreview = (index) => {
    let remainingSelectedImages = []
    const { galleryPreview, selectedFiles } = galleryImages
    const remainingImages = galleryPreview.filter((preview, id) => id !== index)
    if (selectedFiles && !!selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (index !== i) {
          remainingSelectedImages.push(selectedFiles[i])
        }
      }
    }

    setGalleryImages({
      galleryPreview: remainingImages,
      selectedFiles: remainingImages,
    })
  }

  const handleCoverImgClick = () => {
    hiddenFileInput.current.click()
  }

  const handleCoverImgChange = (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setCoverImg({
          imagePreview: URL.createObjectURL(singleImage),
          fileSelected: singleImage,
        })
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const closeCoverImgPreview = (event) => {
    event.preventDefault()
    setCoverImg({
      imagePreview: '',
      fileSelected: null,
    })
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }
    setIsDisabled(true)
    const { name, description } = services
    if (!name || !description) {
      alert('Empty Field')
    } else {
      let serviceDetail = { ...services }
      serviceDetail.coverImg = coverImg.fileSelected

      dispatch(updateService(serviceDetail, 'updateService')).then((data) => {
        setIsDisabled(false)
        if (data.error) {
          alert('Error is updatings service')
        } else {
          history.push('/services')
        }
      })
    }
  }

  const handleRichContents = (pageContent) => {
    setServices({ ...services, seoContent: pageContent })
  }

  const handlePricingFields = (i, e) => {
    let newPricing = [...servicePricings]
    newPricing[i][e.target.name] = e.target.value
    setServicePricings(newPricing)
    setServices({ ...services, pricings: newPricing })
  }

  const addPricingFields = () => {
    setServicePricings([
      ...servicePricings,
      { heading: '', price: '', description: '' },
    ])
  }

  const removePricingField = (i) => {
    const newPricing = [...servicePricings]
    newPricing.splice(i, 1)
    setServicePricings(newPricing)
    setServices({ ...services, pricings: newPricing })
  }

  const handleFaqField = (index, event) => {
    let newContent = [...faqContents]
    newContent[index][event.target.name] = event.target.value
    setFaqContents(newContent)
    setServices({ ...services, faqs: newContent })
  }

  const handleFaqRichTextField = (index, value) => {
    let newContent = [...faqContents]
    newContent[index].description = value
    setFaqContents(newContent)
    setServices({ ...services, faqs: newContent })
  }

  const deleteFaqSection = (index) => {
    const newContent = [...faqContents]
    newContent.splice(index, 1)
    setFaqContents(newContent)
    setServices({ ...services, faqs: newContent })
  }

  const addMoreFaqContents = () => {
    setFaqContents([...faqContents, { heading: '', description: '' }])
  }

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="services"
          selectedTabUrl="services"
          activePage="Edit"
        />

        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">{services.name} Service</h3>
              <button
                onClick={handleUpdate}
                className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase"
                disabled={isDisabled}
              >
                {isDisabled ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-xl-12 col-lg-12">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Basic information</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <div className="col-md-9">
                    <div className="mb-3">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Name</h6>
                      </div>
                      <div className="card-body">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Service Name"
                          defaultValue={services.name}
                          onChange={handleInputChanges}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Url</h6>
                      </div>
                      <div className="card-body">
                        <input
                          type="text"
                          name="slug"
                          defaultValue={services.slug}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Service Slug"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Description</h6>
                      </div>
                      <div className="card-body">
                        <div id="editor">
                          <textarea
                            name="description"
                            defaultValue={services.description}
                            onChange={handleInputChanges}
                            rows="6"
                            className="form-control"
                            placeholder="Service Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Service Type</h6>
                      </div>
                      <div className="card-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="General"
                            checked={services.serviceType === 'General'}
                            onClick={() => handleServiceTypes('General')}
                          />
                          <label className="form-check-label">General</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Premium"
                            checked={services.serviceType === 'Premium'}
                            onClick={() => handleServiceTypes('Premium')}
                          />
                          <label className="form-check-label">Premium</label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Status</h6>
                      </div>
                      <div className="card-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            checked={services.status === 'published'}
                            value="published"
                            onClick={handleStatus}
                          />
                          <label className="form-check-label">Published</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            checked={services.status === 'hidden'}
                            value="Hidden"
                            onClick={handleStatus}
                          />
                          <label className="form-check-label">Hidden</label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Position</h6>
                        <input
                          type="number"
                          name="serviceIndex"
                          defaultValue={services.serviceIndex}
                          onChange={handleInputChanges}
                          placeholder="Order"
                          className="form-control service-index"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12">
          <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
              <h6 className="mb-0 fw-bold white">Pricings</h6>
              <div className="button-section">
                <button type="button" onClick={() => addPricingFields()}>
                  Add
                </button>
              </div>
            </div>
            <div className="card-body">
              {servicePricings.map((ele, index) => (
                <div
                  className="row align-items-center position_relative"
                  key={index}
                >
                  <div className="col-md-3">
                    <label>Heading</label>
                    <input
                      type="text"
                      name="heading"
                      value={ele.heading}
                      onChange={(e) => handlePricingFields(index, e)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Price</label>
                    <input
                      type="number"
                      name="price"
                      min="0"
                      value={ele.price}
                      onChange={(e) => handlePricingFields(index, e)}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-6">
                    <label>Description</label>
                    <input
                      type="text"
                      name="description"
                      value={ele.description}
                      onChange={(e) => handlePricingFields(index, e)}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-1">
                    {index ? (
                      <button
                        type="button"
                        onClick={() => removePricingField(index)}
                        className="btn btn-outline-secondary deleterow"
                      >
                        <i className="icofont-ui-delete text-danger"></i>
                      </button>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-xl-6 col-lg-6">
            <div className="card mb-3">
              <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 mt-2 fw-bold white">Cover Image</h6>
              </div>
              <div className="card-body">
                <form>
                  <div className="row g-3 align-items-center">
                    <div className="col-md-12">
                      <small className="d-block text-muted mb-2">
                        Only portrait or square image, 2M max and 2000px
                        max-height.
                      </small>
                      <div className="fileinput">
                        <div className="service-preview">
                          {coverImg?.imagePreview ? (
                            <Fragment>
                              <div
                                className="post-image"
                                style={{
                                  backgroundImage: `url(${coverImg.imagePreview})`,
                                }}
                              />
                              <i
                                className="material-icons cursor-pointer"
                                onClick={closeCoverImgPreview}
                              >
                                x
                              </i>
                            </Fragment>
                          ) : (
                            <div
                              className="thumbnail"
                              onClick={handleCoverImgClick}
                            >
                              <input
                                className="file"
                                type="file"
                                name="image"
                                onChange={handleCoverImgChange}
                                ref={hiddenFileInput}
                                accept="image/*"
                              />
                              <button
                                type="button"
                                className="btn-simple btn btn-warning"
                              >
                                Select Cover image
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card mb-3">
              <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 mt-2 fw-bold white">Gallery Images</h6>
              </div>
              <div className="card-body">
                <form>
                  <div className="row g-3 align-items-center">
                    <div className="col-md-12">
                      <small className="d-block text-muted mb-2">
                        Only portrait or square images, 2M max and 2000px
                        max-height.
                      </small>
                      <div className="fileinput">
                        {galleryImages.galleryPreview &&
                        galleryImages.galleryPreview.length ? (
                          <div className="service-preview">
                            {galleryImages.galleryPreview.map(
                              (serviceImage, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div
                                      className="post-image"
                                      style={{
                                        backgroundImage: `url(${serviceImage})`,
                                      }}
                                    />
                                    <i
                                      onClick={() => closeGalleryPreview(index)}
                                      className="material-icons cursor-pointer"
                                    >
                                      x
                                    </i>
                                  </Fragment>
                                )
                              }
                            )}
                          </div>
                        ) : null}
                        <div
                          className="thumbnail"
                          onClick={handleGalleryImgClick}
                        >
                          <input
                            className="file"
                            type="file"
                            name="image"
                            multiple
                            onChange={handleGalleryImgChange}
                            ref={hiddenMutipleFileInput}
                            accept="image/*"
                          />
                          <button
                            type="button"
                            className="btn-simple btn btn-warning"
                          >
                            Select Multiple images
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-xl-12 col-lg-12">
            <div className="d-flex py-3 justify-content-between bg-transparent border-bottom-0">
              <h6 className="mb-0 fw-bold white">FAQ</h6>
              <div className="button-section">
                <button type="button" onClick={() => addMoreFaqContents()}>
                  Add
                </button>
              </div>
            </div>
          </div>
          {faqContents.map((ele, index) => (
            <div
              className="row align-items-center position_relative"
              key={index}
            >
              <FaqSection
                {...ele}
                index={index}
                handleSectionContent={handleFaqField}
                deleteContentSection={deleteFaqSection}
                handleRichTextField={handleFaqRichTextField}
              />
            </div>
          ))}
        </div>

        <div className="row g-3 mb-3">
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
              <h6 className="mb-0 fw-bold white">SEO</h6>
            </div>
            <div className="card-body">
              <div className="row g-3 align-items-center">
                <div className="col-md-12">
                  <label className="form-label">Title</label>
                  <div id="editor1">
                    <textarea
                      name="seoTitle"
                      defaultValue={services.seoTitle}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Title"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Keyword</label>
                  <div id="editor2">
                    <textarea
                      name="seoKeywords"
                      defaultValue={services.seoKeywords}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Keywords"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Description</label>
                  <div id="editor3">
                    <textarea
                      name="seoDescription"
                      defaultValue={services.seoDescription}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Description"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Content</label>
                  <RichTextEditor
                    textData={services.seoContent}
                    handleRichContents={handleRichContents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditService
