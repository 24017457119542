import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  forums: [],
  loading: true,
  error: false,
  errorMessage: '',
  forum: {},
}

export const fetchAsyncForums = createAsyncThunk(
  'forum/fetchAsyncForums',
  async () => {
    const response = await http.get(`/api/forum`)
    return response.data
  }
)

export const createNewForum = createAsyncThunk(
  'forum/createNewForum',
  async (forumData, thunkAPI) => {
    const data = await http.post('/api/forum', forumData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncForum = createAsyncThunk(
  'forum/fetchAsyncForum',
  async (forumId) => {
    const response = await http.get(`/api/forum/${forumId}`)
    return response.data
  }
)

export const updateForum = createAsyncThunk(
  'forum/updateForum',
  async (forumData, thunkAPI) => {
    let formData = new FormData()
    let forumDataString = JSON.stringify(forumData)
    formData.append('forum', forumDataString)
    if (forumData.image) {
      formData.append('image', forumData.image)
    }

    const { _id } = forumData
    const data = await http.put(`/api/forum/${_id}`, formData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteAsyncForum = createAsyncThunk(
  'forum/deleteAsyncForum',
  async (forumId, thunkAPI) => {
    const data = await http.delete(`/api/forum/${forumId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const forumSlice = createSlice({
  name: 'forum',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncForums.pending]: () => {},
    [fetchAsyncForums.fulfilled]: (state, { payload }) => {
      return { ...state, forums: payload, loading: false }
    },
    [fetchAsyncForums.rejected]: () => {},
  },
})

export const forumSelector = (state) => state.forum
