/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../../components/spinner'
import {
  fetchAsyncOrders,
  orderSelector,
} from '../../../application/slices/order.slice'

const OrderList = () => {
  const dispatch = useDispatch()
  const { orders, loading } = useSelector(orderSelector)

  useEffect(() => {
    dispatch(fetchAsyncOrders())
  }, [dispatch])

  const fetchOrderStatus = (status) => {
    switch (status) {
      case 'confirmed':
        return 'bg-info'
      case 'delivered':
        return 'bg-success'
      case 'shipped':
        return 'bg-warning'
      case 'cancelled':
        return 'bg-danger'
      default:
        return ''
    }
  }

  return (
    <div className="body d-flex py-3">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Orders</h3>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body overflow-scroll">
                  <table
                    style={{ width: '100%' }}
                    id="myDataTable"
                    className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline"
                    role="grid"
                    aria-describedby="myDataTable_info"
                  >
                    <thead>
                      <tr>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Id
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Item
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Customer Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Payment Info
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Price
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((ord) => {
                        const styleCss = fetchOrderStatus(ord.orderStatus)
                        return (
                          <tr key={ord._id}>
                            <td>
                              <Link to={`/order/${ord._id}`}>
                                <strong>{ord.orderId}</strong>
                              </Link>
                            </td>
                            <td className="d-flex align-items-center">
                              <img
                                src={ord.order[0].productImages[0]}
                                className="avatar lg rounded me-2"
                                alt="profile-image"
                              />
                              <span> {ord.order[0].productName}</span>
                            </td>
                            <td>{ord.customerinfo.fullName}</td>
                            <td>{ord.paymentMode}</td>
                            <td>₹{ord.totalAmount}</td>
                            <td>
                              <span className={`badge ${styleCss}`}>
                                {ord.orderStatus}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderList
