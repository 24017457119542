module.exports = {
  industries: [
    { _id: 1, name: 'Business' },
    { _id: 2, name: 'Education' },
    { _id: 3, name: 'Entertainment' },
    { _id: 4, name: 'Non-Profit' },
    { _id: 5, name: 'Online Communities' },
    { _id: 6, name: 'Podcasting' },
    { _id: 7, name: 'Portfolio' },
    { _id: 8, name: 'Wedding' },
    { _id: 9, name: 'Blog' },
    { _id: 10, name: 'Forms' },
    { _id: 11, name: 'Job Board' },
    { _id: 12, name: 'Crowdfunding' },
    { _id: 13, name: 'Portal' },
    { _id: 14, name: 'Other' },
  ],
  calculateOverallPrice: ({
    cartPrice = 0,
    couponAmount = 0,
    isCOD = false,
  }) => {
    let total = cartPrice
    //if couponAmount is available
    if (couponAmount > 0) {
      total += couponAmount
    }
    //add 49, if cardPrice < 1000
    if (total < 1000) {
      total += 49
    }

    //if cod is applied, additional 39
    if (isCOD) {
      total += 39
    }

    return total.toFixed(2)
  },
}
