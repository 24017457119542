import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  products: [],
  loading: true,
  error: false,
  errorMessage: '',
  product: {},
}

export const fetchAsyncProducts = createAsyncThunk(
  'product/fetchAsyncProducts',
  async () => {
    const response = await http.get(`/api/product`)
    return response.data
  }
)

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (productData, thunkAPI) => {
    let formData = new FormData()
    let productDataString = JSON.stringify(productData)
    formData.append('product', productDataString)
    if (productData.image) {
      const imageFiles = productData.image
      if (imageFiles.length > 0) {
        for (let i = 0; i < imageFiles.length; i++) {
          formData.append('image', imageFiles[i], imageFiles[i].name)
        }
      }
    }
    const data = await http.post('/api/product', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    //! This is the actual data which will be returned as a payload).
    return data
  }
)

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async (productData, thunkAPI) => {
    const { _id } = productData
    // let formData = new FormData()
    // let productDataString = JSON.stringify(product)
    // formData.append('cover', coverUrl)
    // formData.append('display', displayUrl)
    // formData.append('product', productDataString)

    const data = await http.put(`/api/product/${_id}`, productData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (productId, thunkAPI) => {
    const data = await http.delete(`/api/product/${productId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncProduct = createAsyncThunk(
  'product/fetchAsyncProduct',
  async (productId) => {
    const response = await http.get(`/api/product/${productId}`)
    return response.data
  }
)

export const fetchAsyncProductRating = createAsyncThunk(
  'product/fetchAsyncProductRating',
  async (productId) => {
    const response = await http.get(`/api/rating/${productId}/admin`)
    return response.data
  }
)

export const fetchAsyncFilterTypes = createAsyncThunk(
  'product/fetchAsyncFilterTypes',
  async (filterType) => {
    const response = await http.get(`/api/filters/${filterType}`)
    return response.data
  }
)

export const updateAsyncProductRating = createAsyncThunk(
  'product/updateAsyncProductRating',
  async (ratingObj, thunkAPI) => {
    const response = await http.put(
      `/api/rating/userRating/${ratingObj._id}`,
      ratingObj
    )

    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const productSlice = createSlice({
  name: 'product',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncProducts.pending]: () => {},
    [fetchAsyncProducts.fulfilled]: (state, { payload }) => {
      return { ...state, products: payload, loading: false }
    },
    [fetchAsyncProducts.rejected]: () => {},
  },
})

export const productSelector = (state) => state.product
