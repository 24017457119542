import React from 'react'
import TickIcon from '../../../assets/images/droptick.svg'
import './SelectedDropdownList.scss'

const SelectedDropdownList = ({ items, handleClick = () => {} }) => {
  return (
    <div className="selected_search-list">
      {items.map((item, index) => {
        return (
          <div
            className="selected_search-list_selected"
            key={index}
            onClick={() => handleClick(item)}
          >
            <div>
              {item.images && item.images.length > 0 && (
                <img src={item.images[0]} alt="" width="30px" height="30px" />
              )}
              <span className="selected_search-list_selected-title">
                {item.name}
              </span>
            </div>
            <span className="selected_search-list_selected-img">
              <img src={TickIcon} alt="tick" loading="lazy" />
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default SelectedDropdownList
