import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  vendors: [],
  loading: true,
  error: false,
  errorMessage: '',
  vendor: {},
}

export const fetchAsyncVendors = createAsyncThunk(
  'vendor/fetchAsyncVendors',
  async () => {
    const response = await http.get(`/api/vendors`)
    return response.data
  }
)

export const createVendor = createAsyncThunk(
  'vendor/createVendor',
  async (serviceData, thunkAPI) => {
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('service', serviceDataString)

    if (serviceData.coverImg) {
      formData.append('cover', serviceData.coverImg)
    }

    if (serviceData.image) {
      const imageFiles = serviceData.image
      if (imageFiles.length > 0) {
        for (let i = 0; i < imageFiles.length; i++) {
          formData.append('image', imageFiles[i], imageFiles[i].name)
        }
      }
    }
    const data = await http.post('/api/service', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    //! This is the actual data which will be returned as a payload).
    return data
  }
)

export const updateVendor = createAsyncThunk(
  'vendor/updateVendor',
  async (serviceData, thunkAPI) => {
    const { _id } = serviceData
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('service', serviceDataString)

    if (serviceData.coverImg) {
      formData.append('cover', serviceData.coverImg)
    }

    const data = await http.put(`/api/vendor/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteVendor = createAsyncThunk(
  'vendor/deleteVendor',
  async (serviceId, thunkAPI) => {
    const data = await http.delete(`/api/vendor/${serviceId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncVendor = createAsyncThunk(
  'vendor/fetchAsyncVendor',
  async (serviceId, thunkAPI) => {
    const response = await http.get(`/api/vendor/${serviceId}`)
    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const vendorSlice = createSlice({
  name: 'vendors',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncVendors.pending]: () => {},
    [fetchAsyncVendors.fulfilled]: (state, { payload }) => {
      return { ...state, vendors: payload, loading: false }
    },
    [fetchAsyncVendors.rejected]: () => {},
  },
})

export const vendorSelector = (state) => state.vendor
