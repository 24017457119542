import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../../components/spinner'
import {
  fetchAsyncOffers,
  offerSelector,
} from '../../../application/slices/offer.slice'

const OfferList = () => {
  const dispatch = useDispatch()
  const { offers, loading } = useSelector(offerSelector)

  useEffect(() => {
    dispatch(fetchAsyncOffers())
  }, [dispatch])

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Offers</h3>
                <div className="col-auto d-flex w-sm-100">
                  <Link
                    to="/offers/add"
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add New
                    Offer
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body overflow-scroll">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Offer Type</th>
                        <th>Limit</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        <th>Discount</th>
                        <th>Discription</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {offers.map((offer) => {
                        return (
                          <tr key={offer._id}>
                            <td>
                              <span className="fw-bold ms-1">
                                {offer.offerType}
                              </span>
                            </td>
                            <td>{offer.limit}</td>
                            <td>{offer.startDate}</td>
                            <td>{offer.endDate}</td>
                            <td>
                              <span className="badge bg-success">Active</span>
                            </td>
                            <td>{offer.discount}</td>
                            <td>{offer.description}</td>
                            <td>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic outlined example"
                              >
                                <Link
                                  to="/offer/edit/1"
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="icofont-edit text-success"></i>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary deleterow"
                                >
                                  <i className="icofont-ui-delete text-danger"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OfferList
