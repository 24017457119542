import http from './http-common'

const setAuthToken = (token) => {
  if (token) {
    http.defaults.headers.common['x-access-token'] = token
  } else {
    delete http.defaults.headers.common['x-access-token']
  }
}

export default setAuthToken
