import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  loading: true,
  error: false,
  dashboard: {},
}

export const fetchAsyncDashboard = createAsyncThunk(
  'customer/fetchAsyncDashboard',
  async () => {
    const response = await http.get(`/api/dashboard`)
    return response.data
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncDashboard.pending]: () => {},
    [fetchAsyncDashboard.fulfilled]: (state, { payload }) => {
      return { ...state, dashboard: payload, loading: false }
    },
    [fetchAsyncDashboard.rejected]: () => {},
  },
})

export const dashboardSelector = (state) => state.dashboard
