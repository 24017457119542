import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumbs = ({
  activePage = '',
  selectedTabName = '',
  selectedTabUrl = '',
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb p-2">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={`/${selectedTabUrl}`}>{selectedTabName}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {activePage}
        </li>
      </ol>
    </nav>
  )
}

export default BreadCrumbs
