import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  showSidebar: false,
}

export const uploadImageAction = createAsyncThunk(
  'common/uploadImageAction',
  async (imageFile, thunkAPI) => {
    let formData = new FormData()
    formData.append('image', imageFile)

    const data = await http.post('/api/uploadFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialSliceState,
  reducers: {
    toggleOn: (state = initialSliceState) => {
      state.showSidebar = true
    },
    toggleOff: (state = initialSliceState) => {
      state.showSidebar = false
    },
  },
})

export const { toggleOn, toggleOff } = commonSlice.actions
