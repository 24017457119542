import axios from 'axios'
import { apiEndPoint, apiKey } from './config'

const PROD_URI = apiEndPoint
//const DEV_URI = 'http://localhost:4000'

export default axios.create({
  baseURL: PROD_URI,
  headers: {
    'Content-type': 'application/json',
    'x-access-apikey': apiKey,
  },
})
