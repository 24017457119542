import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  profiles: [],
  loading: true,
  error: false,
  errorMessage: '',
  profile: {},
}

export const fetchAsyncDoctors = createAsyncThunk(
  'doctor/fetchAsyncDoctors',
  async () => {
    const response = await http.get(`/api/doctor`)
    return response.data
  }
)

export const createVetProfile = createAsyncThunk(
  'doctor/createVetProfile',
  async (vetData, thunkAPI) => {
    let formData = new FormData()
    let vetDataString = JSON.stringify(vetData)
    formData.append('vetprofile', vetDataString)
    if (vetData.image) {
      const imageFiles = vetData.image
      if (imageFiles.length > 0) {
        for (let i = 0; i < imageFiles.length; i++) {
          formData.append('image', imageFiles[i], imageFiles[i].name)
        }
      }
    }
    const data = await http.post('/api/doctor', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    //! This is the actual data which will be returned as a payload).
    return data
  }
)

export const updateVetProfile = createAsyncThunk(
  'doctor/updateVetProfile',
  async (vetData, thunkAPI) => {
    const { _id } = vetData
    // let formData = new FormData()
    // let productDataString = JSON.stringify(product)
    // formData.append('cover', coverUrl)
    // formData.append('display', displayUrl)
    // formData.append('product', productDataString)

    const data = await http.put(`/api/doctor/${_id}`, vetData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteVetProfile = createAsyncThunk(
  'doctor/deleteVetProfile',
  async (vetId, thunkAPI) => {
    const data = await http.delete(`/api/doctor/${vetId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncdoctor = createAsyncThunk(
  'doctor/fetchAsyncdoctor',
  async (vetId) => {
    const response = await http.get(`/api/doctor/${vetId}`)
    return response.data
  }
)





export const doctorSlice = createSlice({
  name: 'doctor',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncDoctors.pending]: () => {},
    [fetchAsyncDoctors.fulfilled]: (state, { payload }) => {
      return { ...state, Doctors: payload, loading: false }
    },
    [fetchAsyncDoctors.rejected]: () => {},
  },
})

export const doctorSelector = (state) => state.doctor
