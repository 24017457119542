import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  leads: [],
  loading: true,
  error: false,
  errorMessage: '',
}

export const fetchAsyncLeads = createAsyncThunk(
  'lead/fetchAsyncLeads',
  async () => {
    const response = await http.get(`/api/lead`)
    return response.data
  }
)

export const fetchAsyncContact = createAsyncThunk(
  'lead/fetchAsyncContact',
  async (leadId) => {
    const response = await http.get(`/api/lead/${leadId}`)
    return response.data
  }
)

export const leadSlice = createSlice({
  name: 'lead',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncLeads.pending]: () => {},
    [fetchAsyncLeads.fulfilled]: (state, { payload }) => {
      return { ...state, leads: payload, loading: false }
    },
    [fetchAsyncLeads.rejected]: () => {},
  },
})

export const leadSelector = (state) => state.lead
