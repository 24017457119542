import { combineReducers } from '@reduxjs/toolkit'
import { categorySlice } from './slices/category.slice'
import { orderSlice } from './slices/order.slice'
import { productSlice } from './slices/product.slice'
import { authSlice } from './slices/auth.slice'
import { commonSlice } from './slices/common.slice'
import { customerSlice } from './slices/customer.slice'
import { couponSlice } from './slices/coupon.slice'
import { offerSlice } from './slices/offer.slice'
import { contactSlice } from './slices/contact.slice'
import { dashboardSlice } from './slices/dashboard.slice'
import { blogSlice } from './slices/blog.slice'
import { forumSlice } from './slices/forum.slice'
import { serviceSlice } from './slices/service.slice'
import { leadSlice } from './slices/lead.slice'
import { doctorSlice } from './slices/doctor.slice'
import { vendorSlice } from './slices/vendor.slice'
import { shopSlice } from './slices/shop.slice'

const rootReducer = combineReducers({
  category: categorySlice.reducer,
  product: productSlice.reducer,
  service: serviceSlice.reducer,
  auth: authSlice.reducer,
  common: commonSlice.reducer,
  order: orderSlice.reducer,
  customer: customerSlice.reducer,
  coupon: couponSlice.reducer,
  offer: offerSlice.reducer,
  contact: contactSlice.reducer,
  dashboard: dashboardSlice.reducer,
  blog: blogSlice.reducer,
  forum: forumSlice.reducer,
  lead: leadSlice.reducer,
  doctor: doctorSlice.reducer,
  vendor: vendorSlice.reducer,
  shop: shopSlice.reducer,
})

export default rootReducer
