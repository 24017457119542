import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import rootReducer from './root.reducer'
import thunk from 'redux-thunk'

const middleware = [thunk]
const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: true,
})

setupListeners(store.dispatch)
export default store
