import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  contacts: [],
  loading: true,
  error: false,
  errorMessage: '',
  contact: {},
}

export const fetchAsyncContacts = createAsyncThunk(
  'contact/fetchAsyncContacts',
  async () => {
    const response = await http.get(`/api/contact`)
    return response.data
  }
)

export const fetchAsyncContact = createAsyncThunk(
  'contact/fetchAsyncContact',
  async (contactId) => {
    const response = await http.get(`/api/contact/${contactId}`)
    return response.data
  }
)

export const contactSlice = createSlice({
  name: 'contact',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncContacts.pending]: () => {},
    [fetchAsyncContacts.fulfilled]: (state, { payload }) => {
      return { ...state, contacts: payload, loading: false }
    },
    [fetchAsyncContacts.rejected]: () => {},
  },
})

export const contactSelector = (state) => state.contact
