import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  coupons: [],
  loading: true,
  error: false,
  errorMessage: '',
  coupon: {},
}

export const fetchAsyncCoupons = createAsyncThunk(
  'coupon/fetchAsyncCoupons',
  async () => {
    const response = await http.get(`/api/coupon`)
    return response.data?.data
  }
)

export const fetchAsyncCoupon = createAsyncThunk(
  'coupon/fetchAsyncCoupon',
  async (couponId) => {
    const response = await http.get(`/api/coupon/${couponId}`)
    return response.data
  }
)

export const createCoupon = createAsyncThunk(
  'coupon/createCoupon',
  async (couponData, thunkAPI) => {
    const data = await http.post('/api/coupon', couponData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const updateCoupon = createAsyncThunk(
  'coupon/updateCoupon',
  async (couponData, thunkAPI) => {
    const { _id } = couponData
    const data = await http.put(`/api/coupon/${_id}`, couponData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteCoupon = createAsyncThunk(
  'coupon/deleteCoupon',
  async (couponId, thunkAPI) => {
    const data = await http.delete(`/api/coupon/${couponId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const couponSlice = createSlice({
  name: 'coupon',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncCoupons.pending]: () => {},
    [fetchAsyncCoupons.fulfilled]: (state, { payload }) => {
      return { ...state, coupons: payload, loading: false }
    },
    [fetchAsyncCoupons.rejected]: () => {},
  },
})

export const couponSelector = (state) => state.coupon
