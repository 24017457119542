import React, { useState } from 'react'
import './Home.scss'
import { useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  adminLoginHandler,
  authSelector,
} from '../../application/slices/auth.slice'
import { companyName } from '../../utils/config'

const Home = () => {
  const dispatch = useDispatch()
  let history = useHistory()

  const { isAuthenticated } = useSelector(authSelector)
  const [formData, setFormData] = useState({})
  const [error, setError] = useState('')

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const onHandleSubmit = async (event) => {
    event.preventDefault()
    const { mobile, password } = formData
    if (!mobile || !password) {
      alert('Empty Field')
    } else {
      setError('')
      try {
        const response = await dispatch(
          adminLoginHandler(formData, 'auth/login')
        )

        if (response.payload) {
          const { status } = response.payload
          if (status === 'SUCCESS') {
            history.push('/dashboard')
          }
        } else {
          setError(response.error.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />
  }

  return (
    <main className="login_container">
      <section className="login_container-inner">
        <div className="login_container-inner_text">
          <h1 className="login-text">
            <strong>{companyName}</strong>
          </h1>
          <h2 className="admin-title">Welcome to Admin Panel</h2>
        </div>
        {error && <p className="err">{error}</p>}
        <form onSubmit={onHandleSubmit}>
          <div className="form-input-margin">
            <div className="row g-3 mb-3">
              <div className="col-sm-12">
                <label htmlFor="item" className="form-label white">
                  Mobile
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  placeholder="Mobile"
                  name="mobile"
                  onChange={handleFieldChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col-sm-12">
                <label htmlFor="item" className="form-label white">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleFieldChange}
                  required
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="auth-btn-group">
              <button className="button-form" type="submit">
                LOGIN
              </button>
            </div>
          </div>
        </form>
      </section>
    </main>
  )
}

export default Home
