import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  categories: [],
  loading: true,
  error: false,
  errorMessage: '',
  category: {},
}

export const fetchCategoriesSubCategoriesMappings = createAsyncThunk(
  'category/fetchCategoriesSubCategoriesMappings',
  async () => {
    const response = await http.get(`/api/category/home/categoriesMappings`)
    return response.data
  }
)

export const fetchAsyncCategories = createAsyncThunk(
  'category/fetchAsyncCategories',
  async () => {
    const response = await http.get(`/api/category`)
    return response.data
  }
)

export const fetchAsyncParentCategories = createAsyncThunk(
  'category/fetchAsyncParentCategories',
  async () => {
    const response = await http.get(`/api/category/cat/parent`)
    return response.data
  }
)

export const createCategory = createAsyncThunk(
  'category/createCategory',
  async (categoryData, thunkAPI) => {
    let formData = new FormData()
    let categoryDataString = JSON.stringify(categoryData)
    if (categoryData.image) {
      formData.append('cover', categoryData.image)
    }
    formData.append('category', categoryDataString)

    const data = await http.post('/api/category', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async (categoryData, thunkAPI) => {
    const { _id } = categoryData

    let formData = new FormData()
    let categoryDataString = JSON.stringify(categoryData)
    if (categoryData.image) {
      formData.append('cover', categoryData.image)
    }
    formData.append('category', categoryDataString)

    const data = await http.put(`/api/category/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (categoryId, thunkAPI) => {
    const data = await http.delete(`/api/category/${categoryId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncCategory = createAsyncThunk(
  'category/fetchAsyncCategory',
  async (categoryId) => {
    const response = await http.get(`/api/category/${categoryId}`)
    return response.data
  }
)

export const categorySlice = createSlice({
  name: 'category',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncCategories.pending]: () => {},
    [fetchAsyncCategories.fulfilled]: (state, { payload }) => {
      return { ...state, categories: payload, loading: false }
    },
    [fetchAsyncCategories.rejected]: () => {},
  },
})

export const categorySelector = (state) => state.category
