/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import {
  fetchAsyncProduct,
  fetchAsyncProductRating,
  updateAsyncProductRating,
} from '../../../application/slices/product.slice'
import BreadCrumbs from '../../../components/breadcrumbs'
import Avatar from '../../../assets/images/xs/avatar9.svg'

const ProductDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [prod, setProd] = useState({
    rating: { point: 0, counts: 0 },
    spsc: [{ size: null, price: 0, stock: 0, color: null }],
  })

  const [size, setSizes] = useState([])
  const [prodspec, setProdspec] = useState([])
  const [selectedProd, setSelectedProd] = useState({})
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedTab, setSelectedTab] = useState('descriptions')
  const [productRatings, setProductRatings] = useState([])

  useEffect(() => {
    async function fetchParticularProduct(id) {
      //updating particular product
      const { payload } = await dispatch(fetchAsyncProduct(id))
      if (payload && payload._id) {
        setProd(payload)
        sizeCheck(payload.spsc)

        if (payload.images && payload.images.length) {
          setSelectedImage(payload.images[0])
        }

        const { payload: productRatings } = await dispatch(
          fetchAsyncProductRating(id)
        )
        setProductRatings(productRatings)
      }
    }

    fetchParticularProduct(id)
  }, [dispatch, id])

  const sizeCheck = (spsc) => {
    const groupSizes = _.groupBy(spsc, 'size')
    const prods = Object.values(groupSizes)[0]
    setSizes(Object.keys(groupSizes))
    setProdspec(prods)
    setSelectedProd(prods[0])
  }

  const handleSizes = (sz, e) => {
    e.preventDefault()
    const groupSizes = _.groupBy(prod.spsc, 'size')
    const prods = groupSizes[sz]
    setProdspec(prods)
    setSelectedProd(prods[0])
  }

  const handleColors = (col, e) => {
    e.preventDefault()
    setSelectedProd(col)
  }

  const changeImageHandler = (e, image) => {
    e.preventDefault()
    setSelectedImage(image)
  }

  const changeRatingStatus = async (rating) => {
    const ratingObj = {
      ...rating,
      showRating: rating.showRating === 'hide' ? 'show' : 'hide',
    }

    await dispatch(
      updateAsyncProductRating(ratingObj, 'updateAsyncProductRating')
    )
    const { payload: productRatings } = await dispatch(
      fetchAsyncProductRating(id)
    )
    setProductRatings(productRatings)
  }

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Products"
          selectedTabUrl="products"
          activePage={prod.name}
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Products Detail</h3>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="product-details">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="product-details-image mt-50">
                        <div className="product-thumb-image">
                          <div
                            className="product-thumb-image-active nav flex-column nav-pills me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {prod.images && prod.images.length > 0
                              ? prod.images.map((image) => (
                                  <div
                                    className="single-thumb"
                                    id="v-pills-one-tab"
                                    role="button"
                                    aria-controls="v-pills-one"
                                    key={image}
                                    onClick={(e) =>
                                      changeImageHandler(e, image)
                                    }
                                  >
                                    <img src={image} alt="" />
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                        <div className="product-image">
                          <div
                            className="product-image-active tab-content"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="single-image"
                              id="v-pills-one"
                              role="tabpanel"
                              aria-labelledby="v-pills-one-tab"
                            >
                              <img src={selectedImage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="product-details-content mt-45">
                        <h2 className="fw-bold fs-4">{prod.name}</h2>
                        {!prod.rating.point && (
                          <div className="my-3">
                            <i className="fa fa-star text-warning"></i>
                            <i className="fa fa-star text-warning"></i>
                            <i className="fa fa-star text-warning"></i>
                            <i className="fa fa-star text-warning"></i>
                            <i className="fa fa-star text-warning"></i>
                            <span className="text-muted ms-3">
                              ({prod.rating.counts} customer review)
                            </span>
                          </div>
                        )}
                        <div className="product-items flex-wrap">
                          <h6 className="item-title fw-bold">Select Size</h6>
                          <div className="items-wrapper" id="select-item-1">
                            {size.map((sz) => {
                              const commonStyles = {
                                boxShadow: '2px 2px 8px rgb(0 0 0 / 32%)',
                                ...(selectedProd.size === sz && {
                                  border: '1px solid #ffd55d',
                                }),
                              }
                              return sz ? (
                                <div
                                  className="single-item active"
                                  key={sz}
                                  onClick={(e) => handleSizes(sz, e)}
                                  style={commonStyles}
                                >
                                  <p className="text">{sz}</p>
                                </div>
                              ) : (
                                '-'
                              )
                            })}
                          </div>
                        </div>
                        <div className="product-select-wrapper flex-wrap">
                          <div className="select-item">
                            <h6 className="select-title fw-bold">
                              Select Color
                            </h6>
                            <ul className="color-select" id="select-color-1">
                              {prodspec.map((col) => {
                                const commonStyles = {
                                  boxShadow: '2px 2px 8px rgb(0 0 0 / 32%)',
                                  backgroundColor: '#EFEFEF',
                                  ...(selectedProd.color === col.color && {
                                    border: '2px solid #ffd55d',
                                  }),
                                }

                                return col.color ? (
                                  <li
                                    style={commonStyles}
                                    key={col.color}
                                    onClick={(e) => handleColors(col, e)}
                                  >
                                    {col.color}
                                  </li>
                                ) : (
                                  '-'
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="product-price">
                          <h6 className="price-title fw-bold">Price</h6>
                          <p className="sale-price">
                            ₹{' '}
                            {selectedProd?.discount
                              ? selectedProd.price -
                                Math.floor(
                                  (selectedProd.discount / 100) *
                                    selectedProd.price
                                )
                              : selectedProd?.price}
                          </p>
                          <p className="regular-price text-danger">
                            ₹ {selectedProd.price}
                          </p>
                          {'  '}
                          {selectedProd?.discount && (
                            <span className="price-title">
                              ({selectedProd?.discount} %)
                            </span>
                          )}
                        </div>
                        <div className="product-price">
                          <h6 className="price-title fw-bold">Stock</h6>
                          <p className="sale-price"> {selectedProd.stock} </p>
                        </div>
                        <div className="product-btn mb-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-tabs tab-body-header rounded  d-inline-flex"
                  role="tablist"
                >
                  <li className="nav-item">
                    <div
                      className={`nav-link pointer ${
                        selectedTab === 'descriptions' ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => setSelectedTab('descriptions')}
                      role="tab"
                    >
                      Descriptions
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link pointer ${
                        selectedTab === 'review' ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => setSelectedTab('review')}
                      role="tab"
                    >
                      Reviews
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    selectedTab === 'review' ? 'show active' : ''
                  }`}
                  id="review"
                >
                  <div className="card-body">
                    <div className="row clearfix g-3">
                      <div className="col-lg-12 col-md-12">
                        {productRatings.length > 0 ? (
                          <ul className="list-unstyled mb-4">
                            {productRatings.map((pRating) => {
                              return (
                                <li className="card mb-2" key={pRating._id}>
                                  <div className="card-body p-lg-4 p-3">
                                    <div className="d-flex mb-3 pb-3 border-bottom flex-wrap">
                                      <img
                                        className="avatar rounded"
                                        src={Avatar}
                                        alt=""
                                      />
                                      <div className="flex-fill ms-3 text-truncate">
                                        <h6 className="mb-0">
                                          <span>{pRating.fullname}</span>
                                        </h6>
                                        <span className="text-muted">
                                          3 hours ago
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="mb-2 me-3">
                                          {Array.from(
                                            { length: 5 },
                                            (_, i) => i + 1
                                          ).map((star) => {
                                            return (
                                              <a
                                                href="#"
                                                className="rating-link active"
                                                key={star}
                                              >
                                                <i
                                                  className={`bi ${
                                                    star <= pRating.rating
                                                      ? 'bi-star-fill'
                                                      : 'bi-star'
                                                  }  text-warning`}
                                                />
                                              </a>
                                            )
                                          })}
                                        </span>
                                        <button
                                          onClick={() =>
                                            changeRatingStatus(pRating)
                                          }
                                        >
                                          {pRating.showRating === 'hide'
                                            ? 'show'
                                            : 'hide'}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="timeline-item-post">
                                      <p>{pRating.message}</p>
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <div className="row">
                            <p className="center">No ratings added</p>
                          </div>
                        )}
                        <nav aria-label="...">
                          <ul className="pagination justify-content-end">
                            <li className="page-item disabled">
                              <span className="page-link">Previous</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                1
                              </a>
                            </li>
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link">2</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {/* <div className="col-lg-12 col-md-12">
                        <div className="feedback-info sticky-top">
                          <div className="card">
                            <div className="card-body">
                              <h2 className=" display-6 fw-bold mb-0">4.5</h2>
                              <small className="text-muted">
                                based on 1,032 ratings
                              </small>
                              <div className="d-flex align-items-center">
                                <span className="mb-2 me-3">
                                  <a to="#" className="rating-link active">
                                    <i className="bi bi-star-fill text-warning"></i>
                                  </a>
                                  <a to="#" className="rating-link active">
                                    <i className="bi bi-star-fill text-warning"></i>
                                  </a>
                                  <a to="#" className="rating-link active">
                                    <i className="bi bi-star-fill text-warning"></i>
                                  </a>
                                  <a to="#" className="rating-link active">
                                    <i className="bi bi-star-fill text-warning"></i>
                                  </a>
                                  <a to="#" className="rating-link active">
                                    <i className="bi bi-star-half text-warning"></i>
                                  </a>
                                </span>
                              </div>
                              <div className="progress-count mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="mb-0 fw-bold d-flex align-items-center">
                                    5
                                    <i className="bi bi-star-fill text-warning ms-1 small-11 pb-1"></i>
                                  </h6>
                                  <span className="small text-muted">661</span>
                                </div>
                                <div
                                  className="progress"
                                  style={{ height: '10px' }}
                                >
                                  <div
                                    className="progress-bar light-success-bg"
                                    role="progressbar"
                                    style={{ width: '92%' }}
                                    aria-valuenow="92"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div className="progress-count mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="mb-0 fw-bold d-flex align-items-center">
                                    4
                                    <i className="bi bi-star-fill text-warning ms-1 small-11 pb-1"></i>
                                  </h6>
                                  <span className="small text-muted">237</span>
                                </div>
                                <div
                                  className="progress"
                                  style={{ height: '10px' }}
                                >
                                  <div
                                    className="progress-bar bg-info-light"
                                    role="progressbar"
                                    style={{ width: '60%' }}
                                    aria-valuenow="60"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div className="progress-count mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="mb-0 fw-bold d-flex align-items-center">
                                    3
                                    <i className="bi bi-star-fill text-warning ms-1 small-11 pb-1"></i>
                                  </h6>
                                  <span className="small text-muted">76</span>
                                </div>
                                <div
                                  className="progress"
                                  style={{ height: '10px' }}
                                >
                                  <div
                                    className="progress-bar bg-lightyellow"
                                    role="progressbar"
                                    style={{ width: '40%' }}
                                    aria-valuenow="40"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div className="progress-count mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="mb-0 fw-bold d-flex align-items-center">
                                    2
                                    <i className="bi bi-star-fill text-warning ms-1 small-11 pb-1"></i>
                                  </h6>
                                  <span className="small text-muted">19</span>
                                </div>
                                <div
                                  className="progress"
                                  style={{ height: '10px' }}
                                >
                                  <div
                                    className="progress-bar light-danger-bg "
                                    role="progressbar"
                                    style={{ width: '15%' }}
                                    aria-valuenow="15"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div className="progress-count mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-1">
                                  <h6 className="mb-0 fw-bold d-flex align-items-center">
                                    1
                                    <i className="bi bi-star-fill text-warning ms-1 small-11 pb-1"></i>
                                  </h6>
                                  <span className="small text-muted">39</span>
                                </div>
                                <div
                                  className="progress"
                                  style={{ height: '10px' }}
                                >
                                  <div
                                    className="progress-bar bg-careys-pink"
                                    role="progressbar"
                                    style={{ width: '10%' }}
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                              <div className="customer-like mt-5">
                                <h6 className="mb-0 fw-bold white">
                                  What Customers Like
                                </h6>
                                <ul className="list-group mt-3">
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-light-success">
                                        1
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>Fun Factor</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        72 votes
                                      </span>
                                    </div>
                                  </li>
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-light-success">
                                        2
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>Great Value</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        52 votes
                                      </span>
                                    </div>
                                  </li>
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-light-success">
                                        3
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>pet</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        35 votes
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="customer-like mt-5">
                                <h6 className="mb-0 fw-bold white">
                                  What Need Improvement
                                </h6>
                                <ul className="list-group mt-3">
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-careys-pink">
                                        1
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>Value for Money</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        12 votes
                                      </span>
                                    </div>
                                  </li>
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-careys-pink">
                                        2
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>Customer service</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        8 votes
                                      </span>
                                    </div>
                                  </li>
                                  <li className="list-group-item d-flex">
                                    <div className="number border-end pe-2 fw-bold">
                                      <strong className="color-careys-pink">
                                        3
                                      </strong>
                                    </div>
                                    <div className="cs-text flex-fill ps-2">
                                      <span>Product Item</span>
                                    </div>
                                    <div className="vote-text">
                                      <span className="text-muted">
                                        2 votes
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    selectedTab === 'descriptions' ? 'show active' : ''
                  }`}
                  id="descriptions"
                >
                  <div className="card-body">
                    <p className="description">{prod.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetails
