/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import './RichTextEditor.scss'

const RichTextEditor = ({ textData = '', handleRichContents = () => {} }) => {
  const editorRef = useRef(null)
  const [isRendered, setIsRendered] = useState(false)
  const [editorState, setEditorState] = useState(() =>
    EditorState.moveFocusToEnd(EditorState.createEmpty())
  )

  useEffect(() => {
    if (textData && !isRendered) {
      setIsRendered(true)
      //NOTE: set previous save data for editable
      const contentBlock = htmlToDraft(textData)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const editorState = EditorState.moveFocusToEnd(
          EditorState.createWithContent(contentState)
        )
        setEditorState(editorState)
      }
    }

    return () => {
      setIsRendered(false)
    }
  }, [textData])

  const isValidRichTextEditor = () => {
    const content = editorState.getCurrentContent()
    const isEditorEmpty = !content.hasText()
    const currentPlainText = content.getPlainText()
    const lengthOfTrimmedContent = currentPlainText.trim().length
    return !!(!isEditorEmpty && lengthOfTrimmedContent) ? true : false
  }

  const onContentChanged = (content) => {
    setEditorState(content)
    const richTextData = draftToHtml(convertToRaw(content.getCurrentContent()))
    const data = isValidRichTextEditor() ? richTextData : ''
    if (data) {
      handleRichContents(richTextData)
      setIsRendered(true)
    }
  }

  return (
    <Editor
      ref={editorRef}
      editorState={editorState}
      onEditorStateChange={onContentChanged}
      editorClassName="editorClass"
      toolbarClassName="toolbarClass"
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'emoji',
        ],
        inline: {
          inDropdown: false,
          options: [
            'italic',
            'bold',
            'underline',
            'strikethrough',
            'monospace',
            'superscript',
            'subscript',
          ],
          bold: { className: 'demo-option-custom' },
          italic: { className: 'demo-option-custom' },
          underline: { className: 'demo-option-custom' },
          strikethrough: { className: 'demo-option-custom' },
          monospace: { className: 'demo-option-custom' },
          superscript: { className: 'demo-option-custom' },
          subscript: { className: 'demo-option-custom' },
        },
        blockType: {
          inDropdown: true,
          options: [
            'Normal',
            'H1',
            'H2',
            'H3',
            'H4',
            'H5',
            'H6',
            'Blockquote',
            'Code',
          ],
          className: 'demo-option-custom-wide',
          dropdownClassName: 'demo-dropdown-custom',
        },
        fontSize: {
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
          component: undefined,
          className: 'demo-option-custom-wide',
          dropdownClassName: 'demo-dropdown-custom',
        },
        fontFamily: {
          options: [
            'Arial',
            'Georgia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
          ],
          component: undefined,
          className: 'demo-option-custom-wide',
          dropdownClassName: 'demo-dropdown-custom',
        },
        list: {
          inDropdown: false,
          component: undefined,
          className: 'demo-option-custom-wide',
          dropdownClassName: 'demo-dropdown-custom',
          options: ['unordered', 'ordered', 'indent', 'outdent'],
          unordered: { className: 'demo-dropdown-custom' },
          ordered: { className: 'demo-dropdown-custom' },
          indent: { className: 'demo-dropdown-custom' },
          outdent: { className: 'demo-dropdown-custom' },
        },
        textAlign: {
          inDropdown: false,
          className: 'demo-option-custom-wide',
          component: undefined,
          dropdownClassName: 'demo-dropdown-custom',
          options: ['left', 'center', 'right', 'justify'],
          left: { className: 'demo-dropdown-custom' },
          center: { className: 'demo-dropdown-custom' },
          right: { className: 'demo-dropdown-custom' },
          justify: { className: 'demo-dropdown-custom' },
        },
        colorPicker: {
          component: undefined,
          className: 'demo-option-custom-wide',
          popupClassName: undefined,
          colors: [
            'rgb(97,189,109)',
            'rgb(26,188,156)',
            'rgb(84,172,210)',
            'rgb(44,130,201)',
            'rgb(147,101,184)',
            'rgb(71,85,119)',
            'rgb(204,204,204)',
            'rgb(65,168,95)',
            'rgb(0,168,133)',
            'rgb(61,142,185)',
            'rgb(41,105,176)',
            'rgb(85,57,130)',
            'rgb(40,50,78)',
            'rgb(0,0,0)',
            'rgb(247,218,100)',
            'rgb(251,160,38)',
            'rgb(235,107,86)',
            'rgb(226,80,65)',
            'rgb(163,143,132)',
            'rgb(239,239,239)',
            'rgb(255,255,255)',
            'rgb(250,197,28)',
            'rgb(243,121,52)',
            'rgb(209,72,65)',
            'rgb(184,49,47)',
            'rgb(124,112,107)',
            'rgb(209,213,216)',
          ],
        },
        link: {
          inDropdown: false,
          component: undefined,
          className: 'demo-option-custom-wide',
          popupClassName: undefined,
          dropdownClassName: 'demo-dropdown-custom',
          showOpenOptionOnHover: true,
          defaultTargetOption: '_self',
          options: ['link', 'unlink'],
          link: { className: undefined },
          unlink: { className: undefined },
          linkCallback: undefined,
        },
        emoji: {
          component: undefined,
          className: 'demo-option-custom-wide',
          popupClassName: undefined,
          emojis: [
            '😀',
            '😁',
            '😂',
            '😃',
            '😉',
            '😋',
            '😎',
            '😍',
            '😗',
            '🤗',
            '🤔',
            '😣',
            '😫',
            '😴',
            '😌',
            '🤓',
            '😛',
            '😜',
            '😠',
            '😇',
            '😷',
            '😈',
            '👻',
            '😺',
            '😸',
            '😹',
            '😻',
            '😼',
            '😽',
            '🙀',
            '🙈',
            '🙉',
            '🙊',
            '👼',
            '👮',
            '🕵',
            '💂',
            '👳',
            '🎅',
            '👸',
            '👰',
            '👲',
            '🙍',
            '🙇',
            '🚶',
            '🏃',
            '💃',
            '⛷',
            '🏂',
            '🏌',
            '🏄',
            '🚣',
            '🏊',
            '⛹',
            '🏋',
            '🚴',
            '👫',
            '💪',
            '👈',
            '👉',
            '👉',
            '👆',
            '🖕',
            '👇',
            '🖖',
            '🤘',
            '🖐',
            '👌',
            '👍',
            '👎',
            '✊',
            '👊',
            '👏',
            '🙌',
            '🙏',
            '🐵',
            '🐶',
            '🐇',
            '🐥',
            '🐸',
            '🐌',
            '🐛',
            '🐜',
            '🐝',
            '🍉',
            '🍄',
            '🍔',
            '🍤',
            '🍨',
            '🍪',
            '🎂',
            '🍰',
            '🍾',
            '🍷',
            '🍸',
            '🍺',
            '🌍',
            '🚑',
            '⏰',
            '🌙',
            '🌝',
            '🌞',
            '⭐',
            '🌟',
            '🌠',
            '🌨',
            '🌩',
            '⛄',
            '🔥',
            '🎄',
            '🎈',
            '🎉',
            '🎊',
            '🎁',
            '🎗',
            '🏀',
            '🏈',
            '🎲',
            '🔇',
            '🔈',
            '📣',
            '🔔',
            '🎵',
            '🎷',
            '💰',
            '🖊',
            '📅',
            '✅',
            '❎',
            '💯',
          ],
        },
      }}
      handlePastedText={() => false}
    />
  )
}

export default RichTextEditor
