import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ConfirmationModal from '../../../components/modals/confirmation'
import Spinner from '../../../components/spinner'
import {
  fetchAsyncBlogs,
  blogSelector,
  deleteBlog,
} from '../../../application/slices/blog.slice'

const BlogList = () => {
  const dispatch = useDispatch()
  const { blogs = [], loading } = useSelector(blogSelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncBlogs())
  }, [dispatch])

  const deleteBlogHandler = (event, category_id) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(category_id)
  }

  const onApproveConfirmationHandler = (blogId) => {
    setShowModal(false)
    dispatch(deleteBlog(blogId, 'deleteBlog'))
    setSelectedId('')
    dispatch(fetchAsyncBlogs())
  }

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Blogs</h3>
                <div className="col-auto d-flex w-sm-100">
                  <Link
                    to="/blog/add"
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add New
                    Blog
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Tags</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogs.map((blog) => {
                        return (
                          <tr key={blog._id}>
                            <td>
                              {blog.imageUrl ? (
                                <img
                                  src={blog.imageUrl}
                                  alt={blog._id}
                                  style={{ width: '50px', height: '50px' }}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>{blog.title}</td>
                            <td>{blog.categoryName}</td>
                            <td>
                              <span className="badge bg-success">
                                {blog.status}
                              </span>
                            </td>
                            <td>{blog.tag ? blog.tag : '-'}</td>
                            <td>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic outlined example"
                              >
                                <Link
                                  to={`/blog/edit/${blog._id}`}
                                  className="btn btn-outline-secondary"
                                >
                                  <i className="icofont-edit text-success"></i>
                                </Link>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary deleterow"
                                  onClick={(event) =>
                                    deleteBlogHandler(event, blog._id)
                                  }
                                >
                                  <i className="icofont-ui-delete text-danger"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default BlogList
