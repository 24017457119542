import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  blogs: [],
  loading: true,
  error: false,
  errorMessage: '',
}

export const fetchAsyncBlogs = createAsyncThunk(
  'blog/fetchAsyncBlogs',
  async () => {
    const response = await http.get(`/api/blogs`)
    return response.data
  }
)

export const createBlog = createAsyncThunk(
  'blog/createBlog',
  async (blogData, thunkAPI) => {
    let formData = new FormData()
    let blogDataString = JSON.stringify(blogData)
    if (blogData.image) {
      formData.append('cover', blogData.image)
    }

    formData.append('blog', blogDataString)

    const data = await http.post('/api/blogs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const updateBlog = createAsyncThunk(
  'blog/updateBlog',
  async (blogData, thunkAPI) => {
    const { _id } = blogData

    let formData = new FormData()
    let blogDataString = JSON.stringify(blogData)
    formData.append('blog', blogDataString)
    if (blogData.image) {
      formData.append('cover', blogData.image)
    }

    const data = await http.put(`/api/blogs/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteBlog = createAsyncThunk(
  'blog/deleteBlog',
  async (blogId, thunkAPI) => {
    const data = await http.delete(`/api/blogs/${blogId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncBlog = createAsyncThunk(
  'blog/fetchAsyncBlog',
  async (blogId) => {
    const response = await http.get(`/api/blogs/${blogId}`)
    return response.data
  }
)

export const blogSlice = createSlice({
  name: 'blog',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncBlogs.pending]: () => {},
    [fetchAsyncBlogs.fulfilled]: (state, { payload }) => {
      return { ...state, blogs: payload, loading: false }
    },
    [fetchAsyncBlogs.rejected]: () => {},
  },
})

export const blogSelector = (state) => state.blog
