import React from 'react'
import Sidebar from '../sidebar'
import Header from '../header'

const Layout = ({ isLoginPage = false, children, pathName = '' }) => {
  return (
    <div id="pet-layout" className="theme-cyan">
      <div className="row">
        {!isLoginPage && <Sidebar pathName={pathName} />}
        <div className="main px-lg-4 px-md-4">
          {!isLoginPage && <Header />}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout
