/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchAsyncVendor } from '../../../application/slices/vendor.slice'
import BreadCrumbs from '../../../components/breadcrumbs'

const VendorDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [vendorDetail, setVendorDetail] = useState({})
  const [selectedImage, setSelectedImage] = useState('')
  const [selectedTab, setSelectedTab] = useState('descriptions')

  useEffect(() => {
    async function fetchParticularVendor(id) {
      const { payload } = await dispatch(fetchAsyncVendor(id))
      if (payload && payload._id) {
        setVendorDetail(payload)

        if (payload.images && payload.images.length) {
          setSelectedImage(payload.images[0])
        }
      }
    }

    fetchParticularVendor(id)
  }, [dispatch, id])

  const changeImageHandler = (e, image) => {
    e.preventDefault()
    setSelectedImage(image)
  }

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Products"
          selectedTabUrl="products"
          activePage={vendorDetail.name}
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Products Detail</h3>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="product-details">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="product-details-image mt-50">
                        <div className="product-thumb-image">
                          <div
                            className="product-thumb-image-active nav flex-column nav-pills me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {vendorDetail.images &&
                            vendorDetail.images.length > 0
                              ? vendorDetail.images.map((image) => (
                                  <div
                                    className="single-thumb"
                                    id="v-pills-one-tab"
                                    role="button"
                                    aria-controls="v-pills-one"
                                    key={image}
                                    onClick={(e) =>
                                      changeImageHandler(e, image)
                                    }
                                  >
                                    <img src={image} alt="" />
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                        <div className="product-image">
                          <div
                            className="product-image-active tab-content"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="single-image"
                              id="v-pills-one"
                              role="tabpanel"
                              aria-labelledby="v-pills-one-tab"
                            >
                              <img src={selectedImage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="product-details-content mt-45">
                        <h2 className="fw-bold fs-4">{vendorDetail.name}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <ul
                  className="nav nav-tabs tab-body-header rounded  d-inline-flex"
                  role="tablist"
                >
                  <li className="nav-item">
                    <div
                      className={`nav-link pointer ${
                        selectedTab === 'descriptions' ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => setSelectedTab('descriptions')}
                      role="tab"
                    >
                      Descriptions
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link pointer ${
                        selectedTab === 'review' ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => setSelectedTab('review')}
                      role="tab"
                    >
                      Reviews
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className={`tab-pane fade ${
                    selectedTab === 'review' ? 'show active' : ''
                  }`}
                  id="review"
                >
                  <div className="card-body">
                    <div className="row clearfix g-3">
                      <div className="col-lg-12 col-md-12">
                        <nav aria-label="...">
                          <ul className="pagination justify-content-end">
                            <li className="page-item disabled">
                              <span className="page-link">Previous</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                1
                              </a>
                            </li>
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link">2</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" to="#">
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    selectedTab === 'descriptions' ? 'show active' : ''
                  }`}
                  id="descriptions"
                >
                  <div className="card-body">
                    <p className="description">{vendorDetail.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorDetails
