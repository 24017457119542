import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  shops: [],
  loading: true,
  error: false,
  errorMessage: '',
  shop: {},
}

export const fetchAsyncShops = createAsyncThunk(
  'shop/fetchAsyncShops',
  async () => {
    const response = await http.get(`/api/shops`)
    return response.data
  }
)

export const createShop = createAsyncThunk(
  'shop/createShop',
  async (serviceData, thunkAPI) => {
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('shop', serviceDataString)

    if (serviceData.image) {
      formData.append('cover', serviceData.image)
    }

    const data = await http.post('/api/shops', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    //! This is the actual data which will be returned as a payload).
    return data
  }
)

export const updateShop = createAsyncThunk(
  'shop/updateShop',
  async (serviceData, thunkAPI) => {
    const { _id } = serviceData
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('service', serviceDataString)

    if (serviceData.coverImg) {
      formData.append('cover', serviceData.coverImg)
    }

    const data = await http.put(`/api/shops/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteShop = createAsyncThunk(
  'shop/deleteShop',
  async (serviceId, thunkAPI) => {
    const data = await http.delete(`/api/shops/${serviceId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncShop = createAsyncThunk(
  'shop/fetchAsyncShop',
  async (slug, thunkAPI) => {
    const response = await http.get(`/api/shops/fetchDetails/${slug}`)
    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const shopSlice = createSlice({
  name: 'shops',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncShops.pending]: () => {},
    [fetchAsyncShops.fulfilled]: (state, { payload }) => {
      return { ...state, shops: payload, loading: false }
    },
    [fetchAsyncShops.rejected]: () => {},
  },
})

export const shopSelector = (state) => state.shop
