import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toggleOff } from '../../application/slices/common.slice'

const Sidebar = ({ pathName }) => {
  const dispatch = useDispatch()
  const toggleSidebar = useSelector((state) => state.common.showSidebar)
  const applications = useSelector((state) => state.auth.app)

  const onClickItemHandler = () => {
    dispatch(toggleOff())
  }

  if (Object.values(applications).length === 0) {
    return null
  }

  return (
    <div
      className={`sidebar px-4 py-4 py-md-4 me-0 gradient ${
        toggleSidebar ? 'open' : ''
      }`}
    >
      <div className="d-flex flex-column h-100">
        <div className="brand-icon">
          <span className="logo-text">{applications?.companyName}</span>
        </div>
        <ul className="menu-list flex-grow-1 mt-3">
          <li
            className="pointer"
            style={
              applications?.permissions['dashboard']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/dashboard' ? 'active' : ''}`}
              to="/dashboard"
              onClick={onClickItemHandler}
            >
              <i className="icofont-home fs-5"></i> <span>Dashboard</span>
            </Link>
          </li>
          <li className="pointer">
            <Link
              className={`m-link ${pathName === '/shops' ? 'active' : ''}`}
              to="/shops"
              onClick={onClickItemHandler}
            >
              <i className="icofont-dart fs-5"></i> <span>Shops</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['category']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/categories' ? 'active' : ''}`}
              to="/categories"
              onClick={onClickItemHandler}
            >
              <i className="icofont-chart-flow fs-5"></i>{' '}
              <span>Categories</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['product']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/products' ? 'active' : ''}`}
              to="/products"
              onClick={onClickItemHandler}
            >
              <i className="icofont-truck-loaded fs-5"></i>{' '}
              <span>Products</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['orders']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/orders' ? 'active' : ''}`}
              to="/orders"
              onClick={onClickItemHandler}
            >
              <i className="icofont-notepad fs-5"></i> <span>Orders</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['customers']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/customers' ? 'active' : ''}`}
              to="/customers"
              onClick={onClickItemHandler}
            >
              <i className="icofont-ui-user fs-5"></i> <span>Users</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['coupons']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/coupons' ? 'active' : ''}`}
              to="/coupons"
              onClick={onClickItemHandler}
            >
              <i className="icofont-sale-discount fs-5"></i>{' '}
              <span>Coupons</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['contacts']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/contacts' ? 'active' : ''}`}
              to="/contacts"
              onClick={onClickItemHandler}
            >
              <i className="icofont-funky-man fs-5"></i> <span>Contacts</span>
            </Link>
          </li>
          <li
            className="pointer"
            style={
              applications?.permissions['offers']
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <Link
              className={`m-link ${pathName === '/offers' ? 'active' : ''}`}
              to="/offers"
              onClick={onClickItemHandler}
            >
              <i className="icofont-ui-calculator fs-5"></i> <span>Offers</span>
            </Link>
          </li>
          <li className="pointer">
            <Link
              className={`m-link ${pathName === '/blogs' ? 'active' : ''}`}
              to="/blogs"
              onClick={onClickItemHandler}
            >
              <i className="icofont-server fs-5"></i> <span>Blogs</span>
            </Link>
          </li>
          <li className="collapsed">
            <Link
              className={`m-link ${pathName === '/forums' ? 'active' : ''}`}
              to="/forums"
            >
              <i className="icofont-notepad fs-5"></i>
              <span>Forums</span>
            </Link>
          </li>
          <li className="collapsed">
            <Link
              className={`m-link ${pathName === '/services' ? 'active' : ''}`}
              to="/services"
            >
              <i className="icofont-address-book fs-5"></i>
              <span>Services</span>
            </Link>
          </li>
          <li className="collapsed">
            <Link
              className={`m-link ${pathName === '/leads' ? 'active' : ''}`}
              to="/leads"
            >
              <i className="icofont-ui-calculator fs-5"></i>
              <span>Leads</span>
            </Link>
          </li>
          <li className="collapsed">
            <Link
              className={`m-link ${
                pathName.includes('/vendor') ? 'active' : ''
              }`}
              to="/vendors"
            >
              <i className="icofont-file-text fs-5"></i>
              <span>Vendors</span>
            </Link>
          </li>
          {/* <li className="collapsed">
            <Link
              className={`m-link ${pathName === '/doctors' ? 'active' : ''}`}
              to="/doctors"
            >
              <i className="icofont-first-aid fs-5"></i>
              <span>Doctors</span>
            </Link>
          </li> */}
          {/* <li className="collapsed">
            <Link
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#menu-Componentsone"
              to="/accounts"
            >
              <i className="icofont-ui-calculator"></i> <span>Accounts</span>{' '}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5"></span>
            </Link>

            <ul className="sub-menu collapse show" id="menu-Componentsone">
              <li>
                <Link className="ms-link" to="/invoices">
                  Invoices{' '}
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="/expenses">
                  Expenses{' '}
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="/salaryslip">
                  Salary Slip{' '}
                </Link>
              </li>
            </ul>
          </li>
          <li className="collapsed">
            <Link
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#menu-inventory"
              to="/inventory"
            >
              <i className="icofont-chart-histogram fs-5"></i>{' '}
              <span>Inventory</span>{' '}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5"></span>
            </Link>

            <ul className="sub-menu collapse show" id="menu-inventory">
              <li>
                <Link className="ms-link" to="inventory-info.html">
                  Stock List
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="purchase.html">
                  Purchase
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="supplier.html">
                  Supplier
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="returns.html">
                  Returns
                </Link>
              </li>
              <li>
                <Link className="ms-link" to="department.html">
                  Department
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
