/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import BreadCrumbs from '../../../components/breadcrumbs'
import RichTextEditor from '../../../components/richTextEditor'
import {
  updateProduct,
  fetchAsyncProduct,
  fetchAsyncFilterTypes,
} from '../../../application/slices/product.slice'
import { fetchCategoriesSubCategoriesMappings } from '../../../application/slices/category.slice'
import '../products.scss'

const ProductEdit = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  const { id } = useParams()
  const hiddenFileInput = useRef(null)

  const [isDisabled, setIsDisabled] = useState(false)
  const [products, setProducts] = useState({
    featured: false,
    onSale: false,
    isHot: false,
    isNew: false,
    status: 'published',
    name: '',
    spsc: [],
    categoryId: '',
    parentCategorySlug: '',
    parentCategoryId: '',
    description: '',
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
    brandId: '',
    stageId: '',
    breedId: '',
    content: '',
  })
  const [spsc, setSpsc] = useState([
    {
      size: '',
      price: '',
      stock: '',
      color: '',
      discount: '',
    },
  ])
  const [brandLists, setBrandLists] = useState([])
  const [stageLists, setStageLists] = useState([])
  const [breedLists, setBreedLists] = useState([])
  const [image, setImage] = useState({
    productPreview: [],
    selectedFiles: [],
  })
  const [overallCategories, setOverallCategories] = useState({})
  const [selectedParentCategory, setSelectedParentCategory] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncProduct(id)).then((data) => {
      setProducts(data.payload)
      if (
        data.payload &&
        data.payload.images &&
        data.payload.images.length > 0
      ) {
        updateExitingPreviewImage(data.payload.images)
      }
      setSpsc(data.payload.spsc)
      setSelectedParentCategory(data.payload?.parentCategorySlug || '')
    })
  }, [dispatch, id])

  useEffect(() => {
    fetchAllFilters()
    dispatch(fetchCategoriesSubCategoriesMappings()).then((data) =>
      setOverallCategories(data.payload)
    )
  }, [])

  const fetchAllFilters = async () => {
    const [brands, stages, breeds] = await Promise.all([
      dispatch(fetchAsyncFilterTypes('brands')),
      dispatch(fetchAsyncFilterTypes('stages')),
      dispatch(fetchAsyncFilterTypes('breeds')),
    ])

    setBrandLists(brands.payload)
    setStageLists(stages.payload)
    setBreedLists(breeds.payload)
  }

  const handleField = (index, event) => {
    let newSpsc = [...spsc]
    newSpsc[index][event.target.name] = event.target.value
    setSpsc(newSpsc)
    setProducts({ ...products, spsc: spsc })
  }

  const addSpscFields = () => {
    setSpsc([
      ...spsc,
      { size: '', price: '', stock: '', color: '', discount: '' },
    ])
  }

  const removeSpscFields = (index) => {
    const newSpsc = [...spsc]
    newSpsc.splice(index, 1)
    setSpsc(newSpsc)
    setProducts({ ...products, spsc: newSpsc })
  }

  const handleInputChanges = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    if (name === 'parentCategorySlug') {
      setSelectedParentCategory(value)
    }

    setProducts({ ...products, [name]: value })
  }

  const handleStatus = (event) => {
    products.status === 'hidden'
      ? setProducts({ ...products, [event.target.name]: 'published' })
      : setProducts({ ...products, [event.target.name]: 'hidden' })
  }

  const handleSub = (event, field) => {
    setProducts({ ...products, [event.target.name]: !products[field] })
  }

  const PostData = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }
    setIsDisabled(true)
    const { name, categoryId } = products
    if (!name || !categoryId) {
      alert('Empty Field')
    } else {
      dispatch(updateProduct(products, 'updateProduct')).then((data) => {
        setIsDisabled(false)
        if (data.error) {
          alert('Product Not Updated')
        } else {
          history.push('/products')
        }
      })
    }
  }

  const updateExitingPreviewImage = (images) => {
    let previewImg = [],
      imgFiles = []

    for (let i = 0; i < images.length; i++) {
      previewImg.push(images[i])
      imgFiles.push(images[i])
    }

    setImage({
      productPreview: previewImg,
      selectedFiles: imgFiles,
    })
  }

  const handleChange = (event) => {
    const images = event.target.files
    const { productPreview, selectedFiles } = image

    let previewImg = [...productPreview],
      imgFiles = [...selectedFiles]

    if (images && !!images.length) {
      for (let i = 0; i < images.length; i++) {
        let singleImage = images[i]
        if (singleImage.size < 5242880) {
          previewImg.push(URL.createObjectURL(singleImage))
          imgFiles.push(singleImage)
        } else {
          alert(`${singleImage.name} is greater than 5MB`)
        }
      }

      setImage({
        productPreview: previewImg,
        selectedFiles: imgFiles,
      })
    }
    // this line right below will reset the
    // input field so if you removed it you can re-add the same file
    event.target.value = ''
  }

  const closeprofilePicturePreview = (index) => {
    let remainingSelectedImages = []
    const { productPreview, selectedFiles } = image
    const remainingImages = productPreview.filter((_, id) => id !== index)
    if (selectedFiles && !!selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (index !== i) {
          remainingSelectedImages.push(selectedFiles[i])
        }
      }
    }

    setImage({
      productPreview: remainingImages,
      selectedFiles: remainingImages,
    })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleRichContents = (pageContent) => {
    setProducts({ ...products, content: pageContent })
  }

  console.log(overallCategories, 'overallCategories', products)

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Products"
          selectedTabUrl="products"
          activePage={products.name}
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Edit Product</h3>
              <button
                type="submit"
                onClick={PostData}
                className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase"
                disabled={isDisabled}
              >
                {isDisabled ? 'Sending...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <div className="card mb-3 bg-transparent p-2">
              <div className="row g-3 mb-3">
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                      <h6 className="mb-0 fw-bold white">Basic information</h6>
                    </div>
                    <div className="card-body">
                      <div className="row g-3 align-items-center">
                        <div className="col-md-9">
                          <div className=" mb-3">
                            <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                              <h6 className="m-0 fw-bold white">Name</h6>
                            </div>
                            <div className="card-body">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Product Name"
                                defaultValue={products.name}
                                onChange={handleInputChanges}
                                name="name"
                              />
                            </div>
                          </div>
                          <div className=" mb-3">
                            <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                              <h6 className="m-0 fw-bold white">Description</h6>
                            </div>
                            <div className="card-body">
                              <div id="editor">
                                <textarea
                                  name="description"
                                  defaultValue={products.description}
                                  onChange={handleInputChanges}
                                  rows="8"
                                  className="form-control"
                                  placeholder="Product Description"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="card mb-3">
                            <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                              <h6 className="m-0 fw-bold white">Visibility</h6>
                            </div>
                            <div className="card-body">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="featured"
                                  checked={products.featured}
                                  onClick={(e) => handleSub(e, 'featured')}
                                />
                                <label className="form-check-label">
                                  Featured
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isHot"
                                  checked={products.isHot}
                                  onClick={(e) => handleSub(e, 'isHot')}
                                />
                                <label className="form-check-label">Hot</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="onSale"
                                  checked={products.onSale}
                                  onClick={(e) => handleSub(e, 'onSale')}
                                />
                                <label className="form-check-label">Sale</label>
                              </div>
                            </div>
                          </div>
                          <div className="card mb-3">
                            <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                              <h6 className="m-0 fw-bold white">Status</h6>
                            </div>
                            <div className="card-body">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  checked={products.status === 'published'}
                                  value="published"
                                  onClick={handleStatus}
                                />
                                <label className="form-check-label">
                                  Published
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="status"
                                  checked={products.status === 'hidden'}
                                  value="Hidden"
                                  onClick={handleStatus}
                                />
                                <label className="form-check-label">
                                  Hidden
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                      <h6 className="mb-0 fw-bold white">Information</h6>
                      <div className="button-section">
                        <button type="button" onClick={() => addSpscFields()}>
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      {spsc.map((ele, index) => (
                        <div
                          className="row align-items-center position_relative"
                          key={index}
                        >
                          <div className="col-md-2">
                            <label>Size</label>
                            <input
                              type="text"
                              name="size"
                              value={ele.size}
                              onChange={(e) => handleField(index, e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-2">
                            <label>Price</label>
                            <input
                              type="number"
                              name="price"
                              min="0"
                              value={ele.price}
                              onChange={(e) => handleField(index, e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-2">
                            <label>Stock</label>
                            <input
                              type="number"
                              name="stock"
                              value={ele.stock}
                              onChange={(e) => handleField(index, e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-2">
                            <label>Color</label>
                            <input
                              type="text"
                              name="color"
                              value={ele.color}
                              onChange={(e) => handleField(index, e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-2">
                            <label>Discount</label>
                            <input
                              type="text"
                              name="discount"
                              value={ele.discount}
                              onChange={(e) => handleField(index, e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-1">
                            {index ? (
                              <button
                                type="button"
                                onClick={() => removeSpscFields(index)}
                                className="btn btn-outline-secondary deleterow"
                              >
                                <i className="icofont-ui-delete text-danger"></i>
                              </button>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-6 col-lg-6">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                      <h6 className="mb-0 fw-bold white">Images</h6>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="row g-3 align-items-center">
                          <div className="col-md-12">
                            <small className="d-block text-muted mb-2">
                              Only portrait or square images, 2M max and 2000px
                              max-height.
                            </small>
                            <div className="fileinput">
                              {image.productPreview &&
                              image.productPreview.length ? (
                                <div className="product-preview">
                                  {image.productPreview.map(
                                    (productImage, index) => {
                                      return (
                                        <Fragment key={index}>
                                          <div
                                            className="post-image"
                                            style={{
                                              backgroundImage: `url(${productImage})`,
                                            }}
                                          />
                                          <i
                                            onClick={() =>
                                              closeprofilePicturePreview(index)
                                            }
                                            className="material-icons cursor-pointer"
                                          >
                                            x
                                          </i>
                                        </Fragment>
                                      )
                                    }
                                  )}
                                </div>
                              ) : null}
                              <div className="thumbnail" onClick={handleClick}>
                                <input
                                  className="file"
                                  type="file"
                                  name="image"
                                  multiple
                                  onChange={handleChange}
                                  ref={hiddenFileInput}
                                  accept="image/*"
                                />
                                <button
                                  type="button"
                                  className="btn-simple btn btn-warning"
                                >
                                  Select image
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                      <h6 className="m-0 fw-bold white">Parent Categories</h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select"
                        size="2"
                        name="parentCategorySlug"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {overallCategories?.categories?.map((cat) => {
                          const selected =
                            products.parentCategorySlug === cat.slug
                              ? 'selected'
                              : ''

                          return (
                            <option
                              selected={selected}
                              key={cat._id}
                              value={cat.slug}
                            >
                              {cat.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                      <h6 className="m-0 fw-bold white">Sub Categories</h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select"
                        size="3"
                        name="categoryId"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {selectedParentCategory &&
                        overallCategories?.subCategories
                          ? overallCategories?.subCategories[
                              selectedParentCategory
                            ].map((cat) => {
                              const selected =
                                products.categoryId === cat._id
                                  ? 'selected'
                                  : ''

                              return (
                                <option
                                  selected={selected}
                                  key={cat._id}
                                  value={cat._id}
                                >
                                  {cat.name}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                      <h6 className="m-0 fw-bold white">Brands</h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select"
                        size="3"
                        name="brandId"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {brandLists.map((brand) => {
                          const selected =
                            products.brandId === brand._id ? 'selected' : ''

                          return (
                            <option
                              selected={selected}
                              key={brand._id}
                              value={brand._id}
                            >
                              {brand.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                      <h6 className="m-0 fw-bold white">Stages</h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select"
                        size="3"
                        name="stageId"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {stageLists.map((stage) => {
                          const selected =
                            products.stageId === stage._id ? 'selected' : ''

                          return (
                            <option
                              selected={selected}
                              key={stage._id}
                              value={stage._id}
                            >
                              {stage.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="card mb-3">
                    <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                      <h6 className="m-0 fw-bold white">Brands</h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select"
                        size="3"
                        name="breedId"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {breedLists.map((breed) => {
                          const selected =
                            products.breedId === breed._id ? 'selected' : ''

                          return (
                            <option
                              selected={selected}
                              key={breed._id}
                              value={breed._id}
                            >
                              {breed.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mb-3 p-2">
                <div className="card">
                  <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold white">SEO</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-12">
                        <label className="form-label">Title</label>
                        <div id="editor1">
                          <textarea
                            name="seoTitle"
                            defaultValue={products.seoTitle}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Title"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Keyword</label>
                        <div id="editor2">
                          <textarea
                            name="seoKeywords"
                            defaultValue={products.seoKeywords}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Keywords"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Description</label>
                        <div id="editor3">
                          <textarea
                            name="seoDescription"
                            defaultValue={products.seoDescription}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Page Content</h6>
              </div>
              <div className="card-body">
                <RichTextEditor
                  textData={products.content}
                  handleRichContents={handleRichContents}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductEdit
