import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  customers: [],
  loading: true,
  error: false,
  errorMessage: '',
  customer: {},
}

export const fetchAsyncCustomers = createAsyncThunk(
  'customer/fetchAsyncCustomers',
  async () => {
    const response = await http.get(`/api/user`)
    return response.data
  }
)

export const fetchAsyncCustomer = createAsyncThunk(
  'customer/fetchAsyncCustomer',
  async (customerId) => {
    const response = await http.get(`/api/user/${customerId}`)
    return response.data
  }
)

export const deleteCustomer = createAsyncThunk(
  'customer/deleteCustomer',
  async (userId, thunkAPI) => {
    const data = await http.delete(`/api/user/${userId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const customerSlice = createSlice({
  name: 'customer',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncCustomers.pending]: () => {},
    [fetchAsyncCustomers.fulfilled]: (state, { payload }) => {
      return { ...state, customers: payload, loading: false }
    },
    [fetchAsyncCustomers.rejected]: () => {},
  },
})

export const customerSelector = (state) => state.customer
