import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  authSelector,
  changePasswordHandler,
  adminLogoutHandler,
} from '../../application/slices/auth.slice'

const Profile = () => {
  const dispatch = useDispatch()
  const [userProfile, setUserProfile] = useState({})
  const { userAccount } = useSelector(authSelector)

  const handleProfileChanges = (event) => {
    setUserProfile({
      ...userProfile,
      [event.target.name]: event.target.value,
    })
  }

  const savedHandler = (event) => {
    event.preventDefault()

    const { password, newPassword } = userProfile
    if (password && newPassword) {
      if (userAccount && userAccount._id) {
        const passwordObj = {
          _id: userAccount._id,
          password,
          newPassword,
        }

        dispatch(changePasswordHandler(passwordObj), 'password').then(
          (data) => {
            if (data.error) {
              alert('Error in updating password')
            } else {
              dispatch(adminLogoutHandler(), 'logout')
            }
          }
        )
      } else {
        alert('User Not found')
      }
    } else {
      alert('Fields are required')
    }
  }

  return (
    <div className="body d-flex py-3">
      <section className="border border-light">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Profile Settings</h3>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-4">
              <div className="sticky-lg-top">
                <div className="card teacher-card mb-3 flex-column">
                  <div className="card-body d-flex teacher-fulldeatil flex-column">
                    <div className="profile-teacher text-center w220 mx-auto">
                      <img
                        src="https://www.pixelwibes.com/template/ebazar/html/dist/assets/images/xs/avatar2.svg"
                        alt=""
                        className="avatar xl rounded-circle img-thumbnail shadow-sm"
                      />
                      <div className="about-info d-flex align-items-center mt-3 justify-content-center flex-column">
                        <span className="text-muted small">
                          {userAccount?.role}
                        </span>
                      </div>
                    </div>
                    <div className="teacher-info w-100">
                      <h6 className="mb-0 mt-2 fw-bold d-block fs-6 text-center">
                        {userAccount?.name}
                      </h6>
                      <div className="row g-2 pt-2">
                        <div className="col-xl-12">
                          <div className="d-flex align-items-center">
                            <i className="icofont-ui-touch-phone"></i>
                            <span className="ms-2">{userAccount?.mobile}</span>
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="d-flex align-items-center">
                            <i className="icofont-email"></i>
                            <span className="ms-2">{userAccount?.email}</span>
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="d-flex align-items-center">
                            <i className="icofont-address-book"></i>
                            <span className="ms-2">
                              {userAccount?.updatedAt.substring(0, 10)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Update Password</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6">
                      <label className="form-label">Old Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={userProfile?.password}
                        onChange={handleProfileChanges}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="newPassword"
                        value={userProfile?.newPassword}
                        onChange={handleProfileChanges}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary py-2 px-5 text-uppercase btn-set-task w-sm-100 mt-5"
                    onClick={savedHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Profile
