import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import http from '../../utils/http-common'
import setAuthToken from '../../utils/setAuthToken'
import { apiKey } from '../../utils/config'

export const initialSliceState = {
  error: false,
  isAuthenticated: false,
  userId: '',
  userAccount: {},
  app: {},
}

export const setCurrentUserHandler = createAsyncThunk(
  'auth/currentUser',
  async (decoded, thunkAPI) => {
    let data = { ...decoded }
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const adminLoginHandler = createAsyncThunk(
  'auth/login',
  async (adminData, thunkAPI) => {
    const data = await http.post('/auth/adminlogin', adminData)
    data.reduxRequestId = thunkAPI.requestId
    // this is the actual data which will be returned as a payload.

    if (data && data.data && data.data?.status === 'SUCCESS') {
      const {
        result: { token = '' },
      } = data.data
      localStorage.setItem('admin_pet_token', token)
      setAuthToken(token)

      const decoded = jwt_decode(token)
      thunkAPI.dispatch(setCurrentUserHandler(decoded), 'currentUser')
      thunkAPI.dispatch(fetchAsyncPermissions(), 'fetchAsyncPermissions')

      return data.data
    } else {
      return null
    }
  }
)

export const adminLogoutHandler = createAsyncThunk(
  'auth/logout',
  async (_, thunkAPI) => {
    localStorage.removeItem('admin_pet_token')
    setAuthToken('')
    thunkAPI.dispatch(setCurrentUserHandler({}), 'currentUser')
    window.location.href = '/'
  }
)

export const changePasswordHandler = createAsyncThunk(
  'auth/password',
  async (userData, thunkAPI) => {
    const response = await http.put(
      `/api/adminuser/user/change-password`,
      userData
    )
    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const fetchAsyncPermissions = createAsyncThunk(
  'auth/fetchAsyncPermissions',
  async () => {
    const response = await http.get(`/api/permission/${apiKey}`)
    return response.data
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [setCurrentUserHandler.pending]: () => {},
    [setCurrentUserHandler.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        userAccount: payload,
        isAuthenticated: Object.keys(payload).length > 0,
      }
    },
    [setCurrentUserHandler.rejected]: () => {},
    [fetchAsyncPermissions.pending]: () => {},
    [fetchAsyncPermissions.fulfilled]: (state, { payload }) => {
      return { ...state, app: payload }
    },
    [fetchAsyncPermissions.rejected]: () => {},
  },
})

export const authSelector = (state) => state.auth
