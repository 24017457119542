import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ConfirmationModal from '../../../components/modals/confirmation'
import {
  fetchAsyncForums,
  forumSelector,
  deleteAsyncForum,
} from '../../../application/slices/forum.slice'
import Spinner from '../../../components/spinner'

const AllForums = () => {
  const dispatch = useDispatch()
  const { forums, loading } = useSelector(forumSelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncForums())
  }, [dispatch])

  const deleteforumHandler = (event, forum_id) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(forum_id)
  }

  const onApproveConfirmationHandler = (forumId) => {
    setShowModal(false)
    dispatch(deleteAsyncForum(forumId, 'deleteforum'))
    setSelectedId('')
    dispatch(fetchAsyncForums())
  }

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Forums</h3>
                <div className="col-auto d-flex w-sm-100">
                  <Link
                    to="/forum/add"
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add Forum
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body overflow-scroll">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>forum</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Subtitle</th>
                        <th>Date</th>
                        <th>Questions</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {forums.length > 0
                        ? forums.map((forum, index) => {
                            return (
                              <tr key={forum._id}>
                                <td>
                                  <span className="fw-bold ms-1">
                                    {index + 1}
                                  </span>
                                </td>
                                <td>{forum.category}</td>
                                <td>
                                  {forum.image_url ? (
                                    <img
                                      src={forum.image_url}
                                      alt={forum._id}
                                      style={{ width: '50px', height: '50px' }}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td>{forum.title}</td>
                                <td>{forum.subTitle}</td>
                                <td>
                                  {new Date(
                                    forum.createdAt
                                  ).toLocaleDateString()}
                                </td>
                                <td>{forum.totalQuestions}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    <Link
                                      to={`/forum/edit/${forum._id}`}
                                      className="btn btn-outline-secondary"
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      onClick={(event) =>
                                        deleteforumHandler(event, forum._id)
                                      }
                                      className="btn btn-outline-secondary deleterow"
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default AllForums
