import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  services: [],
  loading: true,
  error: false,
  errorMessage: '',
  service: {},
}

export const fetchAsyncServices = createAsyncThunk(
  'service/fetchAsyncServices',
  async () => {
    const response = await http.get(`/api/service`)
    return response.data
  }
)

export const createService = createAsyncThunk(
  'service/createService',
  async (serviceData, thunkAPI) => {
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('service', serviceDataString)

    if (serviceData.coverImg) {
      formData.append('cover', serviceData.coverImg)
    }

    if (serviceData.image) {
      const imageFiles = serviceData.image
      if (imageFiles.length > 0) {
        for (let i = 0; i < imageFiles.length; i++) {
          formData.append('image', imageFiles[i], imageFiles[i].name)
        }
      }
    }
    const data = await http.post('/api/service', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    //! This is the actual data which will be returned as a payload).
    return data
  }
)

export const updateService = createAsyncThunk(
  'service/updateService',
  async (serviceData, thunkAPI) => {
    const { _id } = serviceData
    let formData = new FormData()
    let serviceDataString = JSON.stringify(serviceData)
    formData.append('service', serviceDataString)

    if (serviceData.coverImg) {
      formData.append('cover', serviceData.coverImg)
    }

    const data = await http.put(`/api/service/${_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteService = createAsyncThunk(
  'service/deleteService',
  async (serviceId, thunkAPI) => {
    const data = await http.delete(`/api/service/${serviceId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncService = createAsyncThunk(
  'service/fetchAsyncService',
  async (serviceId, thunkAPI) => {
    const response = await http.get(`/api/service/${serviceId}`)
    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const fetchAsyncServiceRating = createAsyncThunk(
  'service/fetchAsyncServiceRating',
  async (serviceId, thunkAPI) => {
    const response = await http.get(`/api/rating/${serviceId}/admin`)
    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const updateAsyncServiceRating = createAsyncThunk(
  'service/updateAsyncServiceRating',
  async (ratingObj, thunkAPI) => {
    const response = await http.put(
      `/api/rating/userRating/${ratingObj._id}`,
      ratingObj
    )

    response.reduxRequestId = thunkAPI.requestId
    return response.data
  }
)

export const serviceSlice = createSlice({
  name: 'service',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncServices.pending]: () => {},
    [fetchAsyncServices.fulfilled]: (state, { payload }) => {
      return { ...state, services: payload, loading: false }
    },
    [fetchAsyncServices.rejected]: () => {},
  },
})

export const serviceSelector = (state) => state.service
