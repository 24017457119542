import React from 'react'

const ConfirmationModal = ({
  isOpen = true,
  selectedId = '',
  undoSelectedFunc = () => {},
  modalToggleHandler = () => {},
  onApproveConfirmationHandler = () => {},
}) => {
  const resetModalHandler = (event) => {
    event.preventDefault()
    modalToggleHandler(false)
    undoSelectedFunc('')
  }

  return (
    <section
      className={`modal fade ${isOpen ? 'show modalBlur' : ''}`}
      id="exampleModalLive"
      tabIndex="-1"
      style={isOpen ? { display: 'block' } : { display: 'none' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modalShadow">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLiveLabel">
              Confirmation
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetModalHandler}
            />
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this item ?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={resetModalHandler}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onApproveConfirmationHandler(selectedId)}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ConfirmationModal
