/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchAsyncCustomers,
  customerSelector,
  deleteCustomer,
} from '../../application/slices/customer.slice'
import ConfirmationModal from '../../components/modals/confirmation'
import Spinner from '../../components/spinner'

const Customers = () => {
  const dispatch = useDispatch()
  const { customers, loading } = useSelector(customerSelector)
  const [viewContact, setViewContact] = useState('list-view')
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncCustomers())
  }, [dispatch])

  const deleteCustomerHandler = (event, customer_id) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(customer_id)
  }

  const onApproveConfirmationHandler = (customerId) => {
    setShowModal(false)
    dispatch(deleteCustomer(customerId, 'deleteCustomer'))
    setSelectedId('')
    dispatch(fetchAsyncCustomers())
  }

  return (
    <div className="body d-flex py-3">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold py-3 mb-0 white">Customers</h3>
                <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                  <ul
                    className="nav nav-tabs tab-body-header rounded ms-3 prtab-set"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <div
                        className={`nav-link pointer ${
                          viewContact === 'list-view' ? 'active' : ''
                        }`}
                        data-bs-toggle="tab"
                        href="#list-view"
                        role="tab"
                        onClick={() => setViewContact('list-view')}
                      >
                        List View
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        className={`nav-link pointer ${
                          viewContact === 'grid-view' ? 'active' : ''
                        }`}
                        data-bs-toggle="tab"
                        href="#grid-view"
                        role="tab"
                        onClick={() => setViewContact('grid-view')}
                      >
                        Grid View
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                viewContact === 'list-view' ? 'show active' : ''
              }`}
              id="list-view"
            >
              <div className="row clearfix g-3">
                <div className="col-lg-12">
                  <div className="card mb-3">
                    <div className="card-body overflow-scroll">
                      <table
                        id="myProjectTable"
                        className="table table-hover align-middle mb-0"
                        style={{ width: '100%' }}
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Person Name</th>
                            <th>Birthdate</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.map((customer, index) => {
                            return (
                              <tr key={customer._id}>
                                <td>
                                  <strong>{index + 1}</strong>
                                </td>
                                <td>
                                  <img
                                    className="avatar rounded-circle"
                                    src="https://www.pixelwibes.com/template/ebazar/html/dist/assets/images/xs/avatar1.svg"
                                    alt=""
                                  />
                                  <span className="fw-bold ms-1">
                                    {customer.name || '-'}
                                  </span>
                                </td>
                                <td>{customer.createdAt.substring(0, 10)}</td>
                                <td>{customer.email || '-'}</td>
                                <td>{customer.mobile}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#expedit"
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary deleterow"
                                      onClick={(event) =>
                                        deleteCustomerHandler(
                                          event,
                                          customer._id
                                        )
                                      }
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                viewContact === 'grid-view' ? 'show active' : ''
              }`}
              id="grid-view"
            >
              <div className="row clearfix g-3">
                <div className="col-lg-12">
                  <div className="row row-cols-sm-1 row-cols-md-2 row-col-lg-3 row-cols-xl-2 row-cols-xxl-3">
                    {customers.map((customer) => {
                      return (
                        <div className="col" key={customer._id}>
                          <div className="card teacher-card mb-3 flex-column">
                            <div className="card-body d-flex teacher-fulldeatil flex-column">
                              <div className="profile-teacher text-center w220 mx-auto">
                                <a href="#">
                                  <img
                                    src="https://www.pixelwibes.com/template/ebazar/html/dist/assets/images/xs/avatar2.svg"
                                    alt=""
                                    className="avatar xl rounded-circle img-thumbnail shadow-sm"
                                  />
                                </a>
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    position: 'absolute',
                                    top: '15px',
                                    right: '15px',
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#expedit"
                                >
                                  <i className="icofont-edit"></i>
                                </button>
                                {/* <div className="about-info d-flex align-items-center mt-3 justify-content-center flex-column">
                                <span className="text-muted small">
                                  Contact ID : Con-0001
                                </span>
                              </div> */}
                              </div>
                              <div className="teacher-info w-100">
                                <h6 className="mb-0 mt-2 fw-bold d-block fs-6 text-center">
                                  {customer.name || '-'}
                                </h6>
                                {/* <span className="py-1 fw-bold small-11 mb-0 mt-1 text-muted text-center mx-auto d-block">
                                  India
                                </span> */}
                                <div className="row g-2 pt-2">
                                  <div className="col-xl-12">
                                    <div className="d-flex align-items-center">
                                      <i className="icofont-ui-touch-phone"></i>
                                      <span className="ms-2">
                                        {customer.mobile}{' '}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-xl-12">
                                    <div className="d-flex align-items-center">
                                      <i className="icofont-email"></i>
                                      <span className="ms-2">
                                        {customer.email || '-'}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-xl-12">
                                    <div className="d-flex align-items-center">
                                      <i className="icofont-address-book"></i>
                                      <span className="ms-2">
                                        {customer.createdAt.substring(0, 10)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default Customers
