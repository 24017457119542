import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  updateCoupon,
  fetchAsyncCoupon,
} from '../../../application/slices/coupon.slice'
import BreadCrumbs from '../../../components/breadcrumbs'

const CouponsEdit = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [coupon, setCoupon] = useState({})

  useEffect(() => {
    dispatch(fetchAsyncCoupon(id)).then((data) => setCoupon(data.payload))
  }, [dispatch, id])

  const handleCouponChanges = (event) => {
    setCoupon({
      ...coupon,
      [event.target.name]: event.target.value,
    })
  }

  const updateCouponHandler = (event) => {
    event.preventDefault()
    dispatch(updateCoupon(coupon, 'updateCoupon')).then((data) => {
      if (data.error) {
        window.alert('Coupon saving error')
      } else {
        history.push('/coupons')
      }
    })
  }

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Coupons"
          selectedTabUrl="coupons"
          activePage={coupon?._id}
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Coupons Edit</h3>
              <button
                type="submit"
                className="btn btn-primary btn-set-task w-sm-100 text-uppercase px-5"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="row clearfix g-3">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Coupon Information</h6>
              </div>
              <div className="card-body">
                <form>
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6">
                      <label className="form-label">Coupons Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="code"
                        value={coupon?.code}
                        onChange={handleCouponChanges}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Coupons Limits</label>
                      <input
                        type="number"
                        className="form-control"
                        name="minAmount"
                        min={0}
                        value={coupon?.minAmount}
                        onChange={handleCouponChanges}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Coupons Types</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="couponType"
                          value="Fixed Amount"
                          checked={coupon.couponType === 'Fixed Amount'}
                          onChange={handleCouponChanges}
                        />
                        <label className="form-check-label">Fixed Amount</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="couponType"
                          value="Percentage"
                          checked={coupon.couponType === 'Percentage'}
                          onChange={handleCouponChanges}
                        />
                        <label className="form-check-label">Percentage</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="couponType"
                          value="Free Shipping"
                          checked={coupon.couponType === 'Free Shipping'}
                          onChange={handleCouponChanges}
                        />
                        <label className="form-check-label">
                          Free Shipping
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Discount</label>
                      <input
                        type="number"
                        className="form-control"
                        name="discount"
                        value={coupon?.discount}
                        min={0}
                        onChange={handleCouponChanges}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 text-uppercase px-5"
                    onClick={updateCouponHandler}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Status</h6>
              </div>
              <div className="card-body">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    value="active"
                    checked={coupon.status === 'active'}
                    onChange={handleCouponChanges}
                  />
                  <label className="form-check-label">Active</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    value="inactive"
                    checked={coupon.status === 'inactive'}
                    onChange={handleCouponChanges}
                  />
                  <label className="form-check-label">In Active</label>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Schedule</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <div className="col-md-12">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      name="validFrom"
                      className="form-control w-100"
                      value={coupon?.validFrom}
                      onChange={handleCouponChanges}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      name="validTo"
                      className="form-control w-100"
                      value={coupon?.validTo}
                      onChange={handleCouponChanges}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponsEdit
