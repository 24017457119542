/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/spinner'
import {
  fetchAsyncDashboard,
  dashboardSelector,
} from '../../application/slices/dashboard.slice'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { dashboard = {}, loading } = useSelector(dashboardSelector)

  useEffect(() => {
    dispatch(fetchAsyncDashboard())
  }, [dispatch])

  return (
    <div className="body d-flex">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
            <div className="col">
              <div className="alert-success alert mb-0">
                <div className="d-flex align-items-center">
                  <div className="avatar rounded no-thumbnail bg-success text-light">
                    <i className="fa fa-dollar fa-lg"></i>
                  </div>
                  <div className="flex-fill ms-3 text-truncate">
                    <div className="h6 mb-0">Revenue</div>
                    <span className="small">₹ {dashboard?.sales}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="alert-info alert mb-0">
                <div className="d-flex align-items-center">
                  <div className="avatar rounded no-thumbnail bg-info text-light">
                    <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                  </div>
                  <div className="flex-fill ms-3 text-truncate">
                    <div className="h6 mb-0">New Orders</div>
                    <span className="small">{dashboard?.orders}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="alert-warning alert mb-0">
                <div className="d-flex align-items-center">
                  <div className="avatar rounded no-thumbnail bg-warning text-light">
                    <i className="fa fa-smile-o fa-lg"></i>
                  </div>
                  <div className="flex-fill ms-3 text-truncate">
                    <div className="h6 mb-0">Happy Clients</div>
                    <span className="small">{dashboard?.orders}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="alert-danger alert mb-0">
                <div className="d-flex align-items-center">
                  <div className="avatar rounded no-thumbnail bg-danger text-light">
                    <i className="fa fa-credit-card fa-lg"></i>
                  </div>
                  <div className="flex-fill ms-3 text-truncate">
                    <div className="h6 mb-0">Expense</div>
                    <span className="small">₹ 1,50,000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-12 col-md-12">
              <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id="summery-today">
                  <div className="row g-1 g-sm-3 mb-3 row-deck">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <Link to="/customers">
                          <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                            <div className="left-info">
                              <span className="white">Customers</span>
                              <div>
                                <span className="fs-6 fw-bold me-2">
                                  {dashboard?.customers}
                                </span>
                              </div>
                            </div>
                            <div className="right-icon">
                              <i className="icofont-student-alt fs-3 color-light-orange"></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <Link to="/categories">
                          <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                            <div className="left-info">
                              <span className="white">Categories</span>
                              <div>
                                <span className="fs-6 fw-bold me-2">
                                  {dashboard?.category}
                                </span>
                              </div>
                            </div>
                            <div className="right-icon">
                              <i className="icofont-handshake-deal fs-3 color-lavender-purple"></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <Link to="/orders">
                          <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                            <div className="left-info">
                              <span className="white">Order</span>
                              <div>
                                <span className="fs-6 fw-bold me-2">
                                  {dashboard?.orders}
                                </span>
                              </div>
                            </div>
                            <div className="right-icon">
                              <i className="icofont-shopping-cart fs-3 color-lavender-purple"></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <Link to="/products">
                          <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                            <div className="left-info">
                              <span className="white">Total Products</span>
                              <div>
                                <span className="fs-6 fw-bold me-2">
                                  {dashboard?.products}
                                </span>
                              </div>
                            </div>
                            <div className="right-icon">
                              <i className="icofont-bag fs-3 color-light-orange"></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                          <div className="left-info">
                            <span className="white">Avg Sale</span>
                            <div>
                              <span className="fs-6 fw-bold me-2">
                                ₹ {dashboard?.averageSale.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="right-icon">
                            <i className="icofont-sale-discount fs-3 color-santa-fe"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                      <div className="card">
                        <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                          <div className="left-info">
                            <span className="white">Total Sale</span>
                            <div>
                              <span className="fs-6 fw-bold me-2">
                                ₹ {dashboard?.sales.toFixed(2)}
                              </span>
                            </div>
                          </div>
                          <div className="right-icon">
                            <i className="icofont-calculator-alt-1 fs-3 color-lightblue"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div className="card">
                      <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-info">
                          <span className="text-muted">Avg Item Sale</span>
                          <div>
                            <span className="fs-6 fw-bold me-2">185</span>
                          </div>
                        </div>
                        <div className="right-icon">
                          <i className="icofont-calculator-alt-2 fs-3 color-danger"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div className="card">
                      <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-info">
                          <span className="text-muted">Visitors</span>
                          <div>
                            <span className="fs-6 fw-bold me-2">11452</span>
                          </div>
                        </div>
                        <div className="right-icon">
                          <i className="icofont-users-social fs-3 color-light-success"></i>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                    <div className="card">
                      <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                        <div className="left-info">
                          <span className="text-muted">Top Selling Item</span>
                          <div>
                            <span className="fs-6 fw-bold me-2">122</span>
                          </div>
                        </div>
                        <div className="right-icon">
                          <i className="icofont-star fs-3 color-lightyellow"></i>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
