/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import ArrowDownSvg from '../../quarks/ArrowDownSvg'
import ArrowUpSvg from '../../quarks/ArrowUpSvg'
import SelectedDropdownList from '../SelectedDropdownList'
import SearchIcon from '../../../assets/images/search-icon.png'
import './CustomSearchDropdown.scss'

const SearchBox = ({ value = '', handleSearch = () => {}, isOpen = false }) => {
  const inputElement = useRef(null)
  useEffect(() => {
    if (isOpen && inputElement.current) {
      inputElement.current.focus()
    }
  }, [isOpen])

  const handleInputSearchChange = (event) => {
    event.preventDefault()
    let value = event.target.value
    handleSearch(value)
  }

  return (
    <div className="search_box">
      <span className="search_box-icon">
        <img src={SearchIcon} alt="search icon" />
      </span>
      <input
        type="text"
        name="search"
        value={value}
        autoFocus={true}
        autoComplete="off"
        ref={inputElement}
        placeholder="Search"
        className="form-control search_box-input p1"
        onChange={handleInputSearchChange}
      />
    </div>
  )
}

const DropdownList = ({ items = [], handleOptionClick = () => {} }) => {
  return (
    <div className="dropdown_search-list">
      {items.map((item, index) => {
        return (
          <div
            className="dropdown_search-list_item"
            key={index}
            onClick={() => handleOptionClick(item)}
          >
            {item.images && item.images.length > 0 && (
              <img src={item.images[0]} alt="" width="30px" height="30px" />
            )}
            <span className="dropdown_search-list_item-content">
              {item.name}
            </span>
          </div>
        )
      })}
    </div>
  )
}

const DropdownSearch = ({
  items = [],
  isOpen = false,
  filterValue = '',
  selectedValues = [],
  handleFilterChange = () => {},
  handleOptionClick = () => {},
  undoSelectedOption = () => {},
}) => {
  const [showAllOptions, setShowAllOptions] = useState(false)
  const overallOptions =
    selectedValues.length > 0
      ? items.filter(
          ({ name: id1 }) =>
            !selectedValues.some(({ name: id2 }) => id2 === id1)
        )
      : items

  // filter source option array based on filter value and return a new matched options array
  // case-insensitive substring match only
  const matchingOptions = overallOptions.filter((option) =>
    new RegExp(filterValue, 'ig').test(option.name)
  )

  const handleShowRemainder = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setShowAllOptions(true)
    },
    [setShowAllOptions]
  )

  const renderOptions = () => {
    if (showAllOptions) {
      return (
        <DropdownList
          items={matchingOptions}
          handleOptionClick={handleOptionClick}
        />
      )
    }

    const initialOptions = matchingOptions
    return (
      <DropdownList
        items={initialOptions}
        handleOptionClick={handleOptionClick}
        handleShowRemainder={handleShowRemainder}
      />
    )
  }

  return (
    <div className="dropdown_search">
      {/**Search */}
      <SearchBox
        value={filterValue}
        handleSearch={handleFilterChange}
        isOpen={isOpen}
      />
      <div className="mselect">
        {/** Selected */}
        {selectedValues.length > 0 && (
          <SelectedDropdownList
            items={selectedValues}
            handleClick={undoSelectedOption}
          />
        )}
        {/** Overall */}
        {matchingOptions.length > 0 && renderOptions()}
      </div>
    </div>
  )
}

const CustomSearchDropdown = ({
  type,
  items = [],
  defaultValue = '',
  defaultMultipleData = [],
  handleSelectData = () => {},
}) => {
  const wrapperRef = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [selectedValues, setSelectedValues] = useState([])

  // open dropdown
  const handleFieldClick = () => {
    setOpen(() => !isOpen)
  }
  // update filter value state
  const handleFilterChange = (value) => setFilterValue(value)
  const handleOptionClick = (value) => {
    let multiSelectedValues =
      selectedValues.length > 0 ? [...selectedValues, value] : [value]

    handleSelectData(type, multiSelectedValues)
    setSelectedValues(multiSelectedValues)
    //reset text selection
    setFilterValue('')
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target
      // is not within the menu, then close the menu
      if (
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  const undoSelectedOption = (selectedOption) => {
    const someArray = selectedValues.filter(
      (country) => country.name !== selectedOption.name
    )
    setSelectedValues(someArray)
    handleSelectData(type, someArray)
  }

  useMemo(() => {
    if (defaultValue && items && items.length > 0) {
      const selectedItem = items.filter((item) => item.name === defaultValue)
      setSelectedValues(selectedItem)
    }
  }, [defaultValue])

  useMemo(() => {
    if (defaultMultipleData.length > 0 && items && items.length > 0) {
      const selectedItems = items.filter((item) =>
        defaultMultipleData.includes(item.name)
      )
      setSelectedValues(selectedItems)
    }
  }, [defaultMultipleData])

  return (
    <div className="custom_search_dropdown" ref={wrapperRef}>
      <div className="custom_search_dropdown-header" onClick={handleFieldClick}>
        Multi select
        {selectedValues.length > 0 ? (
          <div className="filter_counting">
            :
            <span className="filter_counting__selected">
              {selectedValues.length}
            </span>
          </div>
        ) : null}
        <span className="arrow">
          {isOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
        </span>
      </div>
      <div className={`custom_search_dropdown-content ${isOpen ? 'open' : ''}`}>
        <DropdownSearch
          items={items}
          isOpen={isOpen}
          filterValue={filterValue}
          selectedValues={selectedValues}
          handleFilterChange={handleFilterChange}
          handleOptionClick={handleOptionClick}
          undoSelectedOption={undoSelectedOption}
        />
      </div>
    </div>
  )
}

export default CustomSearchDropdown
