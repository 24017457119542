import React from 'react'
import RichTextEditor from '../richTextEditor'

const FaqSection = ({
  heading = '',
  description = '',
  index = 0,
  handleSectionContent = () => {},
  deleteContentSection = () => {},
  handleRichTextField = () => {},
}) => {
  const handleRichContents = (description) =>
    handleRichTextField(index, description)

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row g-3 align-items-center">
          <div className="col-md-11">
            <div className="col-lg-12 mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                name="heading"
                value={heading}
                onChange={(e) => handleSectionContent(index, e)}
                className="form-control"
                placeholder="Heading"
              />
            </div>

            <div className="col-lg-12 mb-3">
              <label className="form-label">Description</label>
              <div id="editor">
                <RichTextEditor
                  textData={description}
                  handleRichContents={handleRichContents}
                />
              </div>
            </div>
          </div>
          <div className="col-md-1">
            {index ? (
              <button
                type="button"
                onClick={() => deleteContentSection(index)}
                className="btn btn-outline-secondary deleterow"
              >
                <i className="icofont-ui-delete text-danger"></i>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqSection
