import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  orders: [],
  orderDetail: {},
  loading: true,
  error: false,
  errorMessage: '',
  order: {},
}

export const fetchAsyncOrders = createAsyncThunk(
  'order/fetchAsyncOrders',
  async () => {
    const response = await http.get(`/api/order`)
    return response.data
  }
)

export const fetchAsyncOrder = createAsyncThunk(
  'order/fetchAsyncOrder',
  async (orderId) => {
    const response = await http.get(`/api/order/${orderId}`)
    return response.data
  }
)

export const updateOrderStatus = createAsyncThunk(
  'order/updateOrderStatus',
  async (orderData, thunkAPI) => {
    const { id } = orderData
    const data = await http.patch(`/api/order/${id}`, orderData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const orderSlice = createSlice({
  name: 'order',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncOrders.pending]: () => {},
    [fetchAsyncOrders.fulfilled]: (state, { payload }) => {
      return { ...state, orders: payload, loading: false }
    },
    [fetchAsyncOrders.rejected]: () => {},
    [fetchAsyncOrder.pending]: () => {},
    [fetchAsyncOrder.fulfilled]: (state, { payload }) => {
      return { ...state, orderDetail: payload }
    },
    [fetchAsyncOrder.rejected]: () => {},
  },
})

export const orderSelector = (state) => state.order
