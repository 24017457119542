import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  authSelector,
  adminLogoutHandler,
} from '../../application/slices/auth.slice'
import { toggleOff, toggleOn } from '../../application/slices/common.slice'
import ProfileAvatar from '../../assets/images/profile_av.svg'

const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { userAccount } = useSelector(authSelector)
  const toggleSidebar = useSelector((state) => state.common.showSidebar)

  const [showDropDown, setShowDropDown] = useState(false)

  const onClickProfileDropDown = (event) => {
    event.preventDefault()
    setShowDropDown(!showDropDown)
  }

  const onLogoutClickedHandler = (event) => {
    event.preventDefault()
    dispatch(adminLogoutHandler(), 'logout')
  }

  const togglerButtonHandler = (event) => {
    event.preventDefault()
    if (toggleSidebar === true) {
      dispatch(toggleOff())
    } else {
      dispatch(toggleOn())
    }
  }

  const navigateToProfile = () => {
    setShowDropDown(!showDropDown)
    history.push('/profile')
  }

  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
            {/* <div className="d-flex">
              <Link
                className="nav-link text-primary collapsed"
                to="#"
                title="Get Help"
              >
                <i className="icofont-info-square fs-5"></i>
              </Link>
            </div> */}
            {/* <div className="dropdown notifications">
              <Link
                className="nav-link dropdown-toggle pulse"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <i className="icofont-alarm fs-5"></i>
                <span className="pulse-ring"></span>
              </Link>
              <div
                id="NotificationsDiv"
                className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0 mt-3"
              >
                <div className="card border-0 w380">
                  <div className="card-header border-0 p-3">
                    <h5 className="mb-0 font-weight-light d-flex justify-content-between">
                      <span>Notifications</span>
                      <span className="badge text-white">06</span>
                    </h5>
                  </div>
                  <div className="tab-content card-body">
                    <div className="tab-pane fade show active">
                      <ul className="list-unstyled list mb-0">
                        <li className="py-2 mb-1 border-bottom">
                          <Link to="#" className="d-flex">
                            <img
                              className="avatar rounded-circle"
                              src={Avatar1}
                              alt=""
                            />
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Chloe Walkerr
                                </span>{' '}
                                <small>2MIN</small>
                              </p>
                              <span className="">
                                Added New Product 2021-07-15{' '}
                                <span className="badge bg-success">Add</span>
                              </span>
                            </div>
                          </Link>
                        </li>
                        <li className="py-2 mb-1 border-bottom">
                          <Link to="#" className="d-flex">
                            <div className="avatar rounded-circle no-thumbnail">
                              AH
                            </div>
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Alan Hill
                                </span>{' '}
                                <small>13MIN</small>
                              </p>
                              <span className="">Invoice generator </span>
                            </div>
                          </Link>
                        </li>
                        <li className="py-2 mb-1 border-bottom">
                          <Link to="#" className="d-flex">
                            <img
                              className="avatar rounded-circle"
                              src={Avatar3}
                              alt=""
                            />
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Melanie Oliver
                                </span>{' '}
                                <small>1HR</small>
                              </p>
                              <span className="">Orader Return RT-00004</span>
                            </div>
                          </Link>
                        </li>
                        <li className="py-2 mb-1 border-bottom">
                          <Link to="#" className="d-flex">
                            <img
                              className="avatar rounded-circle"
                              src={Avatar5}
                              alt=""
                            />
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Boris Hart
                                </span>{' '}
                                <small>13MIN</small>
                              </p>
                              <span className="">
                                Product Order to Toyseller
                              </span>
                            </div>
                          </Link>
                        </li>
                        <li className="py-2 mb-1 border-bottom">
                          <Link to="#" className="d-flex">
                            <img
                              className="avatar rounded-circle"
                              src={Avatar6}
                              alt=""
                            />
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Alan Lambert
                                </span>{' '}
                                <small>1HR</small>
                              </p>
                              <span className="">Leave Apply</span>
                            </div>
                          </Link>
                        </li>
                        <li className="py-2">
                          <Link to="#" className="d-flex">
                            <img
                              className="avatar rounded-circle"
                              src={Avatar7}
                              alt=""
                            />
                            <div className="flex-fill ms-2">
                              <p className="d-flex justify-content-between mb-0 ">
                                <span className="font-weight-bold">
                                  Zoe Wright
                                </span>{' '}
                                <small className="">1DAY</small>
                              </p>
                              <span className="">
                                Product Stoke Entry Updated
                              </span>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Link className="card-footer text-center border-top-0" to="#">
                    {' '}
                    View all notifications
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
              <div
                className={`nav-link dropdown-toggle pulse p-0  me-2 ${
                  showDropDown ? 'show' : ''
                }`}
                role="button"
                onClick={onClickProfileDropDown}
              >
                <img
                  className="avatar lg rounded-circle img-thumbnail"
                  src={ProfileAvatar}
                  alt="profile"
                />
              </div>
              <div
                className="u-info"
                style={{ cursor: 'pointer' }}
                onClick={onClickProfileDropDown}
              >
                <p className="mb-0 text-end line-height-sm ">
                  <span className="font-weight-bold">
                    {userAccount?.name ?? ''}
                  </span>
                </p>
                <small>{userAccount?.role ?? 'Admin'}</small>
              </div>
              <div
                className={`dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0 ${
                  showDropDown ? 'show' : ''
                }`}
              >
                <div className="card border-0 w300">
                  <div className="card-body pb-0">
                    <div className="d-flex py-1">
                      <img
                        className="avatar rounded-circle"
                        src={ProfileAvatar}
                        alt="profile"
                      />
                      <div className="flex-fill ms-3 text-wrap-style">
                        <p className="mb-0">
                          <span className="font-weight-bold">
                            {userAccount?.name || ''}
                          </span>
                        </p>
                        <small className="">{userAccount?.email || ''}</small>
                      </div>
                    </div>

                    <div>
                      <hr className="dropdown-divider border-dark" />
                    </div>
                  </div>
                  <div className="list-group m-2">
                    <div
                      className="list-group-item list-group-item-action border-0 "
                      onClick={navigateToProfile}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="icofont-ui-user fs-5 me-3"></i>Profile Page
                    </div>
                    <div
                      className="list-group-item list-group-item-action border-0"
                      style={{ cursor: 'pointer' }}
                      onClick={onLogoutClickedHandler}
                    >
                      <i className="icofont-logout fs-5 me-3"></i>Signout
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="setting ms-2">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#Settingmodal"
              >
                <i className="icofont-gear-alt fs-5"></i>
              </Link>
            </div> */}
          </div>

          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainHeader"
            onClick={togglerButtonHandler}
          >
            <span className="fa fa-bars"></span>
          </button>

          <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
            {/* <div className="input-group flex-nowrap input-group-lg">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                aria-label="search"
                aria-describedby="addon-wrapping"
              />
              <button
                type="button"
                className="input-group-text"
                id="addon-wrapping"
              >
                <i className="fa fa-search"></i>
              </button>
            </div> */}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
