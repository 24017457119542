import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ConfirmationModal from '../../../components/modals/confirmation'
import Spinner from '../../../components/spinner'
import {
  fetchAsyncCategories,
  categorySelector,
  deleteCategory,
} from '../../../application/slices/category.slice'

const ListCategory = () => {
  const dispatch = useDispatch()
  const { categories, loading } = useSelector(categorySelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncCategories())
  }, [dispatch])

  const deleteCategoryHandler = (event, category_id) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(category_id)
  }

  const onApproveConfirmationHandler = (categoryId) => {
    setShowModal(false)
    dispatch(deleteCategory(categoryId, 'deleteCategory'))
    setSelectedId('')
    dispatch(fetchAsyncCategories())
  }

  return (
    <div className="body d-flex py-3">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Categories</h3>
                <Link
                  to="/category/add"
                  className="btn btn-primary py-2 px-5 btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i> Add
                  Categories
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body overflow-scroll">
                  <table
                    id="myDataTable"
                    style={{ width: '100%' }}
                    className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline"
                    role="grid"
                    aria-describedby="myDataTable_info"
                  >
                    <thead>
                      <tr>
                        <th
                          className="sorting_asc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Id
                        </th>
                        <th
                          className="sorting_asc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Image
                        </th>
                        <th
                          className="sorting_asc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Name
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Is Parent
                        </th>
                        <th
                          className="sorting_asc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Parent Name
                        </th>
                        <th
                          className="sorting_desc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Products
                        </th>
                        <th
                          className="sorting_asc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Position
                        </th>
                        <th
                          className="sorting_desc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Status
                        </th>
                        <th
                          className="sorting_desc"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Date
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((category, index) => {
                          return (
                            <tr key={category._id}>
                              <td>
                                <strong>{index + 1}</strong>
                              </td>
                              <td>
                                {category.image_url ? (
                                  <img
                                    src={category.image_url}
                                    alt={category._id}
                                    style={{ width: '50px', height: '50px' }}
                                  />
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>{category.name}</td>
                              <td>{category.is_sub ? 'No' : 'Yes'}</td>
                              <td>{category.parent_category_name || '-'}</td>
                              <td>
                                {category.products &&
                                category.products.length > 0
                                  ? category.products.length
                                  : 0}
                              </td>
                              <td>{category.categoryIndex}</td>
                              <td>
                                <span className="badge bg-success">
                                  {category.status}
                                </span>
                              </td>
                              <td>{category.updatedOn}</td>
                              <td>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic outlined example"
                                >
                                  <Link
                                    to={`/category/edit/${category._id}`}
                                    className="btn btn-outline-secondary"
                                  >
                                    <i className="icofont-edit text-success"></i>
                                  </Link>

                                  <button
                                    type="button"
                                    onClick={(event) =>
                                      deleteCategoryHandler(event, category._id)
                                    }
                                    className="btn btn-outline-secondary deleterow"
                                  >
                                    <i className="icofont-ui-delete text-danger"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default ListCategory
