import React from 'react'
import './Spinner.scss'

const Spinner = () => {
  return (
    <section className="loading__container">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
      <p className="loading">Loading...</p>
    </section>
  )
}

export default Spinner
