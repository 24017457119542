/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createVendor } from '../../../application/slices/vendor.slice'
import BreadCrumbs from '../../../components/breadcrumbs'
import RichTextEditor from '../../../components/richTextEditor'
import '../vendor.scss'

const AddVendor = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const hiddenFileInput = useRef(null)

  const [isDisabled, setIsDisabled] = useState(false)
  const [profile, setProfile] = useState({
    firstname: '',
    lastname: '',
    speciality: '',
    gender: '',
    address: '',
    content: '',
    Image: [],
  })
  const [image, setImage] = useState({
    profilePreview: [],
    selectedFiles: [],
  })

  const handleInputChanges = (e) => {
    e.preventDefault()
    setProfile({ ...profile, [e.target.name]: e.target.value })
  }

  const handleChange = (event) => {
    const images = event.target.files
    const { selectedFiles } = image

    let imgFiles = [...selectedFiles]

    if (images && !!images.length) {
      let previewImg = []
      for (let i = 0; i < images.length; i++) {
        let singleImage = images[i]
        if (singleImage.size < 5242880) {
          previewImg.push(URL.createObjectURL(singleImage))
          imgFiles.push(singleImage)
        } else {
          alert(`${singleImage.name} is greater than 5MB`)
        }
      }

      setImage({
        profilePreview: previewImg,
        selectedFiles: imgFiles,
      })
    }
    // this line right below will reset the
    // input field so if you removed it you can re-add the same file
    event.target.value = ''
  }

  const submitData = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }
    setIsDisabled(true)
    const { firstname, lastname, speciality, gender } = profile
    if (!firstname || !lastname || !speciality || !gender) {
      alert('Empty Field')
    } else {
      let profileDetail = { ...profile }
      profileDetail.Image = image.selectedFiles

      dispatch(createVendor(profileDetail, 'createVendor')).then((data) => {
        setIsDisabled(false)
        if (data.error) {
          alert('Error is creating vendor')
        } else {
          history.push('/doctors')
        }
      })
    }
  }

  const handleSub = (e, gender) => {
    // Prevent the default form behavior
    e.preventDefault()

    // Update the state with the selected gender
    setProfile({
      ...profile,
      gender: gender,
    })
    console.log({ profile })
  }
  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleRichContents = (pageContent) => {
    setProfile({ ...profile, content: pageContent })
  }

  return (
    <div className="body d-flex py-3">
      <div className="container-xxl">
        <BreadCrumbs
          selectedTabName="Vendors"
          selectedTabUrl="vendors"
          activePage="New"
        />
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Add Vendor</h3>
              <button
                onClick={submitData}
                className="btn btn-primary btn-set-task w-sm-100 py-2 px-5 text-uppercase"
                disabled={isDisabled}
              >
                {isDisabled ? 'Sending...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-xl-12 col-lg-12">
            <div className="card mb-3">
              <div className="card-header py-3  d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Basic information</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-top">
                  <div className="col-md-9">
                    <div className="card mb-3 ">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="mb-0 fw-bold white">Image</h6>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row g-3 align-items-center">
                            <div className="col-md-12">
                              <div className="fileinput">
                                <div className="vendor-preview">
                                  {image.profilePreview.map(
                                    (profileImage, index) => {
                                      return (
                                        <Fragment key={index}>
                                          <div
                                            className="post-image"
                                            style={{
                                              backgroundImage: `url(${profileImage})`,
                                            }}
                                          />
                                        </Fragment>
                                      )
                                    }
                                  )}
                                </div>

                                <small className="d-block text-muted mb-2">
                                  Only portrait or square images, 2M max and
                                  2000px max-height.
                                </small>
                                <div
                                  className="thumbnail"
                                  onClick={handleClick}
                                >
                                  <input
                                    className="file"
                                    type="file"
                                    name="image"
                                    multiple
                                    onChange={handleChange}
                                    ref={hiddenFileInput}
                                    accept="image/*"
                                  />
                                  <button
                                    type="button"
                                    className="btn-simple btn btn-warning"
                                  >
                                    Select image
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className=" mb-3">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">First Name</h6>
                      </div>
                      <div className="card-body">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          defaultValue={profile.firstname}
                          onChange={handleInputChanges}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className=" mb-3">
                      <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Last Name</h6>
                      </div>
                      <div className="card-body">
                        <div id="editor">
                          <input
                            type="text"
                            name="last name"
                            defaultValue={profile.lastname}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="last name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Gender</h6>
                      </div>
                      <div className="card-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="male"
                            checked={profile.male}
                            onClick={(e) => handleSub(e, 'male')}
                          />
                          <label className="form-check-label">Male</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="female"
                            checked={profile.female}
                            onClick={(e) => handleSub(e, 'female')}
                          />
                          <label className="form-check-label">Female</label>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Speciality</h6>
                      </div>
                      <div className="card-body">
                        <div id="editor">
                          <textarea
                            name="description"
                            defaultValue={profile.speciality}
                            onChange={handleInputChanges}
                            rows="8"
                            className="form-control"
                            placeholder="Speciality"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                        <h6 className="m-0 fw-bold white">Address</h6>
                      </div>
                      <div className="card-body">
                        <div id="editor">
                          <textarea
                            name="description"
                            defaultValue={profile.address}
                            onChange={handleInputChanges}
                            rows="8"
                            className="form-control"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          {/* <div className="col-xl-6 col-lg-6">
            <div className="card mb-3">
              <div className="card-header d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Images</h6>
              </div>
              <div className="card-body">
                <form>
                  <div className="row g-3 align-items-center">
                    <div className="col-md-12">
                      <small className="d-block text-muted mb-2">
                        Only portrait or square images, 2M max and 2000px
                        max-height.
                      </small>
                      <div className="fileinput">
                        {image.profilePreview && image.profilePreview.length ? (
                          <div className="vendor-preview">
                            {image.profilePreview.map((profileImage, index) => {
                              return (
                                <Fragment key={index}>
                                  <div
                                    className="post-image"
                                    style={{
                                      backgroundImage: `url(${profileImage})`,
                                    }}
                                  />
                                  <i
                                    onClick={() =>
                                      closeprofilePicturePreview(index)
                                    }
                                    className="material-icons cursor-pointer"
                                  >
                                    x
                                  </i>
                                </Fragment>
                              )
                            })}
                          </div>
                        ) : null}
                        <div className="thumbnail" onClick={handleClick}>
                          <input
                            className="file"
                            type="file"
                            name="image"
                            multiple
                            onChange={handleChange}
                            ref={hiddenFileInput}
                            accept="image/*"
                          />
                          <button
                            type="button"
                            className="btn-simple btn btn-warning"
                          >
                            Select image
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
          {/* <div className="col-xl-6 col-lg-6">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Categories</h6>
              </div>
              <div className="card-body">
                <select
                  className="form-select"
                  size="3"
                  name="categoryId"
                  onChange={handleInputChanges}
                  aria-label="size 3 select example"
                >
                  {categories.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="row g-3 mb-3">
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
              <h6 className="mb-0 fw-bold white">SEO</h6>
            </div>
            <div className="card-body">
              <div className="row g-3 align-items-center">
                <div className="col-md-12">
                  <label className="form-label">Title</label>
                  <div id="editor1">
                    <textarea
                      name="seoTitle"
                      defaultValue={products.seoTitle}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Title"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Keyword</label>
                  <div id="editor2">
                    <textarea
                      name="seoKeywords"
                      defaultValue={products.seoKeywords}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Keywords"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Description</label>
                  <div id="editor3">
                    <textarea
                      name="seoDescription"
                      defaultValue={products.seoDescription}
                      onChange={handleInputChanges}
                      className="form-control"
                      placeholder="SEO Description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row g-3 mb-3">
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
              <h6 className="mb-0 fw-bold white">Content</h6>
            </div>
            <div className="card-body">
              <RichTextEditor handleRichContents={handleRichContents} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddVendor
