import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchAsyncCoupons,
  couponSelector,
} from '../../../application/slices/coupon.slice'
import Spinner from '../../../components/spinner'

const CouponsList = () => {
  const dispatch = useDispatch()
  const { coupons = [], loading } = useSelector(couponSelector)

  useEffect(() => {
    
    dispatch(fetchAsyncCoupons()) 
  }, [dispatch])

  const fetchCouponStatus = (status) => {
    switch (status) {
      case 'active':
        return 'bg-success'
      case 'inactive':
        return 'bg-danger'
      default:
        return ''
    }
  }

  

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Coupons</h3>
                <div className="col-auto d-flex w-sm-100">
                  <Link
                    to="/coupon/add"
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add Coupons
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body overflow-scroll">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Discount</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Min Amount</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.length > 0
                        ? coupons.map((coupon) => {
                            const statusStyle = fetchCouponStatus(coupon.status)
                            return (
                              <tr key={coupon._id}>
                                <td>
                                  <span className="fw-bold ms-1">
                                    {coupon.code}
                                  </span>
                                </td>
                                <td>{coupon.couponType}</td>
                                <td>{coupon.discount}</td>
                                <td>{coupon.validFrom}</td>
                                <td>{coupon.validTo}</td>
                                <td>{coupon.minAmount}</td>
                                <td>
                                  <span className={`badge ${statusStyle}`}>
                                    {coupon.status}
                                  </span>
                                </td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    <Link
                                      to={`/coupon/edit/${coupon._id}`}
                                      className="btn btn-outline-secondary"
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary deleterow"
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CouponsList
