import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import RichTextEditor from '../richTextEditor'
import { uploadImageAction } from '../../application/slices/common.slice'

const BlogSection = ({
  title = '',
  description = '',
  externalUrl = '',
  imageUrl = '',
  index = 0,
  handleSectionContent = () => {},
  deleteContentSection = () => {},
  handleRichTextField = () => {},
  handleContentImageField = () => {},
}) => {
  const dispatch = useDispatch()
  const hiddenFileInput = React.useRef(null)
  const [singleBlogImage, setSingleBlogImage] = React.useState({
    preview: '',
    file: '',
  })

  useEffect(() => {
    if (imageUrl) {
      setSingleBlogImage({
        preview: imageUrl,
        file: imageUrl,
      })
    }
  }, [imageUrl])

  const handleImageChange = async (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setSingleBlogImage({
          preview: URL.createObjectURL(singleImage),
          file: singleImage,
        })

        // Call API to fetch URL and save in content
        const response = await dispatch(
          uploadImageAction(singleImage, 'uploadImageAction')
        )
        const { payload } = response

        if (payload?.data?.imageUrl) {
          handleContentImageField(index, payload.data.imageUrl)
        }
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const removePicturePreview = (event) => {
    event.preventDefault()
    setSingleBlogImage({
      preview: '',
      file: null,
    })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleRichContents = (description) =>
    handleRichTextField(index, description)

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row g-3 align-items-center">
          <div className="col-md-11">
            <div className="col-lg-12 mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                name="title"
                defaultValue={title}
                onChange={(e) => handleSectionContent(index, e)}
                className="form-control"
                placeholder="Blog Title"
              />
            </div>
            <div className="card py-3 px-3">
              <label className="form-label white">Image</label>
              {singleBlogImage.preview ? (
                <div className="fileinput text-center">
                  <div className="image-preview">
                    {singleBlogImage.preview && (
                      <React.Fragment>
                        <div
                          className="post-image"
                          style={{
                            backgroundImage: `url(${singleBlogImage.preview})`,
                          }}
                        />
                        <i
                          onClick={removePicturePreview}
                          className="material-icons cursor-pointer"
                        >
                          x
                        </i>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              ) : (
                <div className="thumbnail" onClick={handleClick}>
                  <input
                    className="file"
                    type="file"
                    name="image"
                    multiple
                    onChange={handleImageChange}
                    ref={hiddenFileInput}
                    accept="image/*"
                  />
                  <button type="button" className="btn-simple btn btn-warning">
                    Select Content Image
                  </button>
                </div>
              )}
            </div>

            <div className="col-lg-12 mb-3">
              <label className="form-label">Description</label>
              <div id="editor">
                <RichTextEditor
                  textData={description}
                  handleRichContents={handleRichContents}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <label className="form-label">External Url</label>
              <input
                type="text"
                name="externalUrl"
                defaultValue={externalUrl}
                onChange={(e) => handleSectionContent(index, e)}
                className="form-control"
                placeholder="External Url"
              />
            </div>
          </div>
          <div className="col-md-1">
            {index ? (
              <button
                type="button"
                onClick={() => deleteContentSection(index)}
                className="btn btn-outline-secondary deleterow"
              >
                <i className="icofont-ui-delete text-danger"></i>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogSection
