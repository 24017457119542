import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../utils/http-common'

export const initialSliceState = {
  offers: [],
  loading: true,
  error: false,
  errorMessage: '',
  offer: {},
}

export const fetchAsyncOffers = createAsyncThunk(
  'offer/fetchAsyncOffers',
  async () => {
    const response = await http.get(`/api/offer`)
    return response.data
  }
)

export const createOffer = createAsyncThunk(
  'offer/createOffer',
  async (categoryData, thunkAPI) => {
    const data = await http.post('/api/offer', categoryData)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const deleteOffer = createAsyncThunk(
  'offer/deleteOffer',
  async (offerId, thunkAPI) => {
    const data = await http.delete(`/api/offer/${offerId}`)
    data.reduxRequestId = thunkAPI.requestId
    return data
  }
)

export const fetchAsyncOffer = createAsyncThunk(
  'offer/fetchAsyncOffer',
  async (offerId) => {
    const response = await http.get(`/api/offer/${offerId}`)
    return response.data
  }
)

export const offerSlice = createSlice({
  name: 'offer',
  initialState: initialSliceState,
  reducers: {},
  extraReducers: {
    [fetchAsyncOffers.pending]: () => {},
    [fetchAsyncOffers.fulfilled]: (state, { payload }) => {
      return { ...state, offers: payload, loading: false }
    },
    [fetchAsyncOffers.rejected]: () => {},
  },
})

export const offerSelector = (state) => state.offer
