import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchAsyncProducts,
  productSelector,
} from '../../../application/slices/product.slice'
import { createOffer } from '../../../application/slices/offer.slice'
import CustomSearchDropdown from '../../../components/dropdown/CustomSearchDropdown'

const AddOffer = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { products } = useSelector(productSelector)
  const [offerValues, setOfferValues] = useState({
    limit: 1,
  })

  useEffect(() => {
    dispatch(fetchAsyncProducts())
  }, [dispatch])

  const handleSelectData = (type, values) => {
    setOfferValues({
      ...offerValues,
      [type]: values,
    })
  }

  const handleOfferChanges = (event) => {
    setOfferValues({
      ...offerValues,
      [event.target.name]: event.target.value,
    })
  }

  const onHandleClick = (event) => {
    event.preventDefault()
    dispatch(createOffer(offerValues, 'createOffer')).then((data) => {
      if (data.error) {
        window.alert('Offer saving error')
      } else {
        history.push('/offers')
      }
    })
  }

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h3 className="fw-bold mb-0 white">Add New Offer</h3>
            </div>
          </div>
        </div>
        <div className="row clearfix g-3">
          <div className="col-lg-8">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Offer Information</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <div className="col-md-8">
                    <label className="form-label">Select Products</label>
                    <CustomSearchDropdown
                      type="products"
                      items={products}
                      handleSelectData={handleSelectData}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Offers Limits</label>
                    <input
                      type="number"
                      name="limit"
                      className="form-control"
                      min="1"
                      value={offerValues.limit}
                      onChange={handleOfferChanges}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Discount value</label>
                    <input
                      type="number"
                      name="discount"
                      min="1"
                      className="form-control"
                      value={offerValues.discount}
                      onChange={handleOfferChanges}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Description</label>
                    <textarea
                      name="description"
                      defaultValue={offerValues.description}
                      onChange={handleOfferChanges}
                      rows="8"
                      className="form-control"
                      placeholder="Offer Description"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={onHandleClick}
                  className="btn btn-primary mt-4 text-uppercase px-5"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Offer Type</h6>
              </div>
              <div className="card-body">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="offerType"
                    value="wheel"
                    onChange={handleOfferChanges}
                  />
                  <label className="form-check-label">Wheel</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="offerType"
                    value="lucky"
                    onChange={handleOfferChanges}
                  />
                  <label className="form-check-label">Lucky</label>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                <h6 className="m-0 fw-bold white">Date Schedule</h6>
              </div>
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <div className="col-md-12">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      className="form-control w-100"
                      name="startDate"
                      value={offerValues.startDate}
                      required
                      onChange={handleOfferChanges}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      className="form-control w-100"
                      name="endDate"
                      value={offerValues.endDate}
                      required
                      onChange={handleOfferChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOffer
