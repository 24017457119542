/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import BreadCrumbs from '../../../components/breadcrumbs'
import RichTextEditor from '../../../components/richTextEditor'
import {
  updateCategory,
  fetchAsyncCategory,
  fetchAsyncParentCategories,
} from '../../../application/slices/category.slice'
import '../category.scss'

const EditCategory = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const hiddenFileInput = useRef(null)
  const [category, setCategory] = useState([])
  const [categoryParent, setCategoryParent] = useState([])
  const [categoryImage, setCategoryImage] = useState({
    preview: '',
    file: '',
  })
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    dispatch(fetchAsyncCategory(id)).then((data) => {
      setCategory(data.payload)
      if (data.payload && data.payload.image_url) {
        setCategoryImage({
          preview: data.payload.image_url,
          file: data.payload.image_url,
        })
      }
    })
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchAsyncParentCategories()).then((data) => {
      setCategoryParent(data.payload)
    })
  }, [dispatch])

  const handleInputChanges = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    let categoryObject = { ...category }

    if (name === 'name') {
      const titleSlug = value
        ? value.toLowerCase().replace(/&'/g, '').split(' ').join('-')
        : ''

      categoryObject.name = value
      categoryObject.slug = titleSlug
    } else if (name === 'parent_id') {
      const parentCategory = categoryParent.find(
        (category) => category._id === value
      )

      categoryObject.parent_id = value
      categoryObject.parent_category_name = parentCategory.name
    } else {
      categoryObject = { ...categoryObject, [name]: value }
    }

    setCategory(categoryObject)
  }

  const handleStatus = (e) => {
    category.status === 'hidden'
      ? setCategory({ ...category, [e.target.name]: 'published' })
      : setCategory({ ...category, [e.target.name]: 'hidden' })
  }

  const handleRadioBtn = (e) => {
    category.is_sub
      ? setCategory({ ...category, [e.target.name]: false })
      : setCategory({ ...category, [e.target.name]: true })
  }

  const handleComingSoonBtn = (e) => {
    category.isComingSoon
      ? setCategory({ ...category, [e.target.name]: false })
      : setCategory({ ...category, [e.target.name]: true })
  }

  const postData = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }
    setIsDisabled(true)
    const { name, description } = category
    if (!category.is_sub) {
      delete category.parent_id
      delete category.parent_category_name
    }

    if (!name || !description) {
      alert('Empty Field')
    } else {
      let categoryDetail = { ...category }
      categoryDetail.image = categoryImage.file
      dispatch(updateCategory(categoryDetail, 'updateCategory')).then(
        (data) => {
          setIsDisabled(false)
          if (data.error) {
            alert(data.error)
          } else {
            history.push('/categories')
          }
        }
      )
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setCategoryImage({
          preview: URL.createObjectURL(singleImage),
          file: singleImage,
        })
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const closeprofilePicturePreview = (event) => {
    event.preventDefault()
    setCategoryImage({
      preview: '',
      file: null,
    })
  }

  const handleRichContents = (pageContent) => {
    setCategory({ ...category, content: pageContent })
  }

  return (
    <div className="body d-flex py-3">
      <section className="border border-light">
        <div className="container-xxl">
          <BreadCrumbs
            selectedTabName="Categories"
            selectedTabUrl="categories"
            activePage={category.name}
          />
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Edit Category</h3>
                <button
                  onClick={postData}
                  className="btn btn-primary py-2 px-5 text-uppercase btn-set-task w-sm-100"
                  disabled={isDisabled}
                >
                  {isDisabled ? 'Sending...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Basic Details</h6>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row g-3 align-items-center">
                      <div className="col-md-6">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={category.name}
                          onChange={handleInputChanges}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Url</label>
                        <input
                          type="text"
                          name="slug"
                          defaultValue={category.slug}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Category Url"
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Description</label>
                        <div id="editor">
                          <textarea
                            placeholder="Enter Description"
                            name="description"
                            defaultValue={category.description}
                            onChange={handleInputChanges}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-header py-3 bg-transparent border-bottom-0">
                  <h6 className="m-0 fw-bold white">Image</h6>
                </div>
                <div className="card-body">
                  {categoryImage.preview ? (
                    <div className="fileinput text-center">
                      <div className="image-preview">
                        {categoryImage.preview && (
                          <Fragment>
                            <div
                              className="post-image"
                              style={{
                                backgroundImage: `url(${categoryImage.preview})`,
                              }}
                            />
                            <i
                              onClick={closeprofilePicturePreview}
                              className="material-icons cursor-pointer"
                            >
                              x
                            </i>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="thumbnail" onClick={handleClick}>
                      <input
                        className="file"
                        type="file"
                        name="image"
                        multiple
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        accept="image/*"
                      />
                      <button
                        type="button"
                        className="btn-simple btn btn-warning"
                      >
                        Select image
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">SEO</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-12">
                      <label className="form-label">Title</label>
                      <div id="editor1">
                        <textarea
                          name="seoTitle"
                          defaultValue={category.seoTitle}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Keywords</label>
                      <div id="editor2">
                        <textarea
                          name="seoKeywords"
                          defaultValue={category.seoKeywords}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Keywords"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Description</label>
                      <div id="editor3">
                        <textarea
                          name="seoDescription"
                          defaultValue={category.seoDescription}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-lg-top">
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Status</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        checked={category.status === 'published'}
                        value="published"
                        onClick={handleStatus}
                      />
                      <label className="form-check-label">Published</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        checked={category.status === 'hidden'}
                        value="hidden"
                        onClick={handleStatus}
                      />
                      <label className="form-check-label">Hidden</label>
                    </div>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Parent Category</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_sub"
                        checked={category.is_sub}
                        onClick={handleRadioBtn}
                      />
                      <label className="form-check-label">Sub Category</label>
                    </div>
                  </div>
                  {category.is_sub && (
                    <div className="card-body">
                      <label className="form-label">
                        Parent category Select
                      </label>
                      <select
                        className="form-select"
                        size="3"
                        name="parent_id"
                        onChange={handleInputChanges}
                        aria-label="size 3 select example"
                      >
                        {categoryParent.map((catParent) => {
                          const selectedOption =
                            catParent._id === category.parent_id
                              ? 'selected'
                              : ''

                          return (
                            <option
                              key={catParent._id}
                              value={catParent._id}
                              selected={selectedOption}
                            >
                              {catParent.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )}
                </div>
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Position</h6>
                    <input
                      type="number"
                      name="categoryIndex"
                      defaultValue={category.categoryIndex}
                      onChange={handleInputChanges}
                      placeholder="Order"
                      className="form-control category-index"
                    />
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Coming Soon</h6>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="isComingSoon"
                        checked={category.isComingSoon}
                        onClick={handleComingSoonBtn}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Page Content</h6>
                </div>
                <div className="card-body">
                  <RichTextEditor
                    textData={category?.content}
                    handleRichContents={handleRichContents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EditCategory
