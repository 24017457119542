import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/spinner'
import {
  fetchAsyncContacts,
  contactSelector,
} from '../../application/slices/contact.slice'

const Contacts = () => {
  const dispatch = useDispatch()
  const { contacts = [], loading } = useSelector(contactSelector)

  useEffect(() => {
    dispatch(fetchAsyncContacts())
  }, [dispatch])

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Contacts</h3>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body overflow-scroll">
                  <table
                    id="myDataTable"
                    className="table table-hover align-middle mb-0 nowrap dataTable no-footer dtr-inline"
                    role="grid"
                    aria-describedby="myDataTable_info"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                        >
                          Id
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                        >
                          Customers
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                        >
                          Email / Phone / Created On
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="myDataTable"
                          rowspan="1"
                          colSpan="1"
                        >
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((customer, index) => {
                        return (
                          <tr key={customer._id}>
                            <td>
                              <strong>{index + 1}</strong>
                            </td>
                            <td>
                              <span className="fw-bold">
                                {customer.name || '-'}
                              </span>
                            </td>
                            <td>
                              <p>
                                Email:{' '}
                                <span className="fw-bold">
                                  {customer.email}
                                </span>
                              </p>
                              <p>
                                Mobile:{' '}
                                <span className="fw-bold">
                                  {customer.mobile || '-'}
                                </span>
                              </p>
                              <p>
                                Created: {customer.createdAt.substring(0, 10)}
                              </p>
                            </td>
                            <td style={{ width: '600px' }}>
                              {customer.message}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Contacts
