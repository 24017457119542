import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {
  fetchAsyncVendors,
  vendorSelector,
  deleteVendor,
} from '../../../application/slices/vendor.slice'
import Spinner from '../../../components/spinner'
import ConfirmationModal from '../../../components/modals/confirmation'

import DummyImg from '../../../assets/images/product.jpg'
dayjs.extend(localizedFormat)

const Vendorlist = () => {
  const dispatch = useDispatch()
  const { vendors, loading } = useSelector(vendorSelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncVendors())
  }, [dispatch])

  const deleteServiceHandler = (event, serviceId) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(serviceId)
  }

  const onApproveConfirmationHandler = (serviceId) => {
    setShowModal(false)
    dispatch(deleteVendor(serviceId, 'deleteVendor'))
    setSelectedId('')
    dispatch(fetchAsyncVendors())
  }

  return (
    <div className="body d-flex py-3">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Vendors</h3>
                <Link
                  to="/vendor/add"
                  className="btn btn-primary py-2 px-5 btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i> Add Vendor
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="mb-3 bg-transparent">
                {vendors && vendors.length > 0
                  ? vendors.map((service) => {
                      const createdOn = dayjs(service?.createdAt).format('lll')

                      return (
                        <div className="card border-0 mb-1" key={service._id}>
                          <div className="card-body d-flex align-items-center flex-column flex-md-row overflow-scroll">
                            <Link to="#">
                              <img
                                className="w120 rounded img-fluid"
                                src={service.image ? service.image : DummyImg}
                                alt=""
                              />
                            </Link>
                            <div className="ms-md-4 m-0 mt-4 mt-md-0 text-md-start text-center w-100">
                              <Link to="#">
                                <h6 className="mb-3 fw-bold">{service.name}</h6>
                              </Link>
                              <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                                <section className="d-flex">
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Service Type
                                    </div>
                                    <strong>{service.serviceType}</strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Featured
                                    </div>
                                    <strong>Yes</strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Order
                                    </div>
                                    <strong>{service.serviceIndex}</strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">Date</div>
                                    <strong>{createdOn}</strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Status
                                    </div>
                                    <strong>{service.status}</strong>
                                  </div>
                                </section>
                                <section className="d-flex">
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <Link
                                      to={`/vendor/edit/${service._id}`}
                                      className="btn btn-outline-secondary"
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      onClick={(event) =>
                                        deleteServiceHandler(event, service._id)
                                      }
                                      className="btn btn-outline-secondary deleterow"
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-md-12">
                  <nav className="justify-content-end d-flex">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <Link className="page-link" to="#" tabIndex="-1">
                          Previous
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          1
                        </Link>
                      </li>
                      <li className="page-item active" aria-current="page">
                        <Link className="page-link" to="#">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          3
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default Vendorlist
