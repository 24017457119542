import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  deleteProduct,
  fetchAsyncProducts,
  productSelector,
} from '../../../application/slices/product.slice'
import ConfirmationModal from '../../../components/modals/confirmation'
import Spinner from '../../../components/spinner'
import DummyImg from '../../../assets/images/product.jpg'

const ProductList = () => {
  const dispatch = useDispatch()
  const { products, loading } = useSelector(productSelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    dispatch(fetchAsyncProducts())
  }, [dispatch])

  const deleteProductHandler = (event, product_id) => {
    event.preventDefault()
    setShowModal(true)
    setSelectedId(product_id)
  }

  const onApproveConfirmationHandler = (productId) => {
    setShowModal(false)
    dispatch(deleteProduct(productId, 'deleteProduct'))
    setSelectedId('')
    dispatch(fetchAsyncProducts())
  }

  return (
    <div className="body d-flex py-3">
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Products</h3>
                <Link
                  to="/product/add"
                  className="btn btn-primary py-2 px-5 btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i> Add Product
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="mb-3 bg-transparent">
                {products && products.length > 0
                  ? products.map((pro) => {
                      return (
                        <div className="card border-0 mb-1" key={pro._id}>
                          <div className="card-body d-flex align-items-center flex-column flex-md-row overflow-scroll">
                            <Link to={`/product-detail/${pro._id}`}>
                              <img
                                className="w120 rounded img-fluid"
                                src={
                                  pro.images && pro.images.length
                                    ? pro.images[0]
                                    : DummyImg
                                }
                                alt=""
                              />
                            </Link>
                            <div className="ms-md-4 m-0 mt-4 mt-md-0 text-md-start text-center w-100">
                              <Link to={`/product-detail/${pro._id}`}>
                                <h6 className="mb-3 fw-bold">{pro?.name}</h6>
                              </Link>
                              <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                                <section className="d-flex">
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Parent Categories
                                    </div>
                                    <strong>
                                      {pro?.parentCategorySlug
                                        ? pro.parentCategorySlug.toUpperCase()
                                        : '-'}
                                    </strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Product Category
                                    </div>
                                    <strong>{pro.category?.name}</strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">
                                      Featured
                                    </div>
                                    <strong>
                                      {pro.featured ? 'Yes' : 'No'}
                                    </strong>
                                  </div>
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <div className="text-muted small">Date</div>
                                    <strong>{pro.updatedOn}</strong>
                                  </div>
                                </section>
                                <section className="d-flex">
                                  <div className="pe-xl-5 pe-md-4 ps-md-0 px-3 mb-2">
                                    <Link
                                      to={`/product/edit/${pro._id}`}
                                      className="btn btn-outline-secondary"
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </Link>
                                    <button
                                      type="button"
                                      onClick={(event) =>
                                        deleteProductHandler(event, pro._id)
                                      }
                                      className="btn btn-outline-secondary deleterow"
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
              <div className="row g-3 mb-3">
                <div className="col-md-12">
                  <nav className="justify-content-end d-flex">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <Link className="page-link" to="#" tabIndex="-1">
                          Previous
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          1
                        </Link>
                      </li>
                      <li className="page-item active" aria-current="page">
                        <Link className="page-link" to="#">
                          2
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          3
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link className="page-link" to="#">
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModal
        isOpen={showModal}
        selectedId={selectedId}
        undoSelectedFunc={setSelectedId}
        modalToggleHandler={setShowModal}
        onApproveConfirmationHandler={onApproveConfirmationHandler}
      />
    </div>
  )
}

export default ProductList
