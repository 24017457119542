import React, { useState, Fragment, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import BreadCrumbs from '../../../components/breadcrumbs'
import BlogSection from '../../../components/blogSection'
import {
  updateBlog,
  fetchAsyncBlog,
} from '../../../application/slices/blog.slice'
import {
  fetchAsyncCategories,
  categorySelector,
} from '../../../application/slices/category.slice'
const AUTHORS = ['Vishakha', 'Ruchi', 'Nupur']

const EditBlog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const hiddenFileInput = useRef(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [blogObj, setBlogObj] = useState({
    status: 'published',
  })
  const [contents, setContents] = useState([
    {
      title: '',
      description: '',
      externalUrl: '',
      imageUrl: '',
    },
  ])
  const [blogImage, setBlogImage] = useState({
    preview: '',
    file: '',
  })
  const { categories } = useSelector(categorySelector)

  useEffect(() => {
    async function fetchFunction() {
      await dispatch(fetchAsyncCategories())
      await dispatch(fetchAsyncBlog(id)).then((data) => {
        const { payload } = data
        setBlogObj(payload)
        setContents(payload.contents)
        if (payload?.imageUrl) {
          setBlogImage({
            preview: payload.imageUrl,
            file: payload.imageUrl,
          })
        }
      })
    }

    fetchFunction()

    return () => {
      setBlogObj({ status: 'published' })
      setBlogImage({ preview: '', file: '' })
      setContents([
        {
          title: '',
          description: '',
          externalUrl: '',
          imageUrl: '',
        },
      ])
    }
  }, [dispatch, id])

  const handleInputChanges = (event) => {
    event.preventDefault()

    const { name, value } = event.target
    let blogObject = { ...blogObj }

    if (name === 'title') {
      const titleSlug = value
        ? value.toLowerCase().replace(/&'/g, '').split(' ').join('-')
        : ''

      blogObject.title = value
      blogObject.slug = titleSlug
    } else {
      blogObject = { ...blogObject, [name]: value }
    }

    setBlogObj(blogObject)
  }

  const handleStatus = (e) => {
    blogObj.status === 'hidden'
      ? setBlogObj({ ...blogObj, [e.target.name]: 'published' })
      : setBlogObj({ ...blogObj, [e.target.name]: 'hidden' })
  }

  const handleCategoryChanges = (e) => {
    e.preventDefault()
    const filteredCategory = categories.find(
      (category) => category._id === e.target.value
    )

    setBlogObj({
      ...blogObj,
      categoryId: filteredCategory._id,
      categoryName: filteredCategory.name,
      categoryUrl: filteredCategory.slug,
    })
  }

  const handleField = (index, event) => {
    let newContent = [...contents]
    newContent[index][event.target.name] = event.target.value
    setContents(newContent)
  }

  const handleRichTextField = (index, value) => {
    let newContent = [...contents]
    newContent[index].description = value
    setContents(newContent)
  }

  const handleContentImageField = (index, value) => {
    let newContent = [...contents]
    newContent[index].imageUrl = value
    setContents(newContent)
  }

  const addMoreContents = () => {
    setContents([
      ...contents,
      { title: '', description: '', externalUrl: '', imageUrl: '' },
    ])
  }

  const deleteContentSection = (index) => {
    const newContent = [...contents]
    newContent.splice(index, 1)
    setContents(newContent)
  }

  const handleImageChange = (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setBlogImage({
          preview: URL.createObjectURL(singleImage),
          file: singleImage,
        })
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const removePicturePreview = (event) => {
    event.preventDefault()
    setBlogImage({
      preview: '',
      file: null,
    })
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const updateBlogData = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }

    setIsDisabled(true)
    let blogDetail = { ...blogObj }
    if (blogImage && blogImage.file) {
      blogDetail.image = blogImage.file
    }

    blogDetail.contents = contents
    dispatch(updateBlog(blogDetail, 'updateBlog'))
      .then((data) => {
        setIsDisabled(false)
        if (data.error) {
          alert('Error in update')
        } else {
          history.push('/blogs')
        }
      })
      .catch((err) => console.error(err))
  }

  return (
    <div className="body d-flex py-3">
      <section className="border border-light">
        <div className="container-xxl">
          <BreadCrumbs
            selectedTabName="Blogs"
            selectedTabUrl="blogs"
            activePage={blogObj.title}
          />
          <div className="row align-items-center">
            <div className="border-0">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Edit Blog</h3>
                <button
                  onClick={updateBlogData}
                  className="btn btn-primary py-2 px-5 text-uppercase btn-set-task w-sm-100"
                  disabled={isDisabled}
                >
                  {isDisabled ? 'Sending...' : 'Save'}
                </button>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-9">
                <div className="card">
                  <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold white">Basic Details</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-12">
                        <label className="form-label">Title</label>
                        <input
                          type="text"
                          name="title"
                          defaultValue={blogObj.title}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Blog Title"
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Url</label>
                        <input
                          type="text"
                          name="slug"
                          defaultValue={blogObj.slug}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Blog Url"
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Short Description</label>
                        <div id="editor">
                          <textarea
                            name="shortDescription"
                            defaultValue={blogObj.shortDescription}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="Short Description"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Tags</label>
                        <input
                          type="text"
                          name="tag"
                          defaultValue={blogObj.tag}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Tags"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card mb-1">
                  <div className="card-header pt-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Status</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        checked={blogObj.status === 'published'}
                        defaultValue="published"
                        onClick={handleStatus}
                      />
                      <label className="form-check-label">Published</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        checked={blogObj.status === 'hidden'}
                        defaultValue="hidden"
                        onClick={handleStatus}
                      />

                      <label className="form-check-label">Hidden</label>
                    </div>
                  </div>
                </div>

                <div className="card mb-1">
                  <div className="card-header pt-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Categories</h6>
                  </div>
                  <div className="card-body">
                    <select
                      className="form-select"
                      size="3"
                      name="categoryId"
                      onChange={handleCategoryChanges}
                      aria-label="select"
                    >
                      {categories.map((cat) => {
                        const selected =
                          blogObj.categoryId === cat._id ? 'selected' : ''

                        return (
                          <option
                            key={cat._id}
                            value={cat._id}
                            selected={selected}
                          >
                            {cat.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header pt-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Author Name</h6>
                  </div>
                  <div className="card-body">
                    <select
                      className="form-select"
                      size="2"
                      name="authorName"
                      onChange={handleInputChanges}
                      aria-label="author name"
                    >
                      {AUTHORS.map((author) => {
                        const selected =
                          blogObj.authorName === author ? 'selected' : ''

                        return (
                          <option
                            key={author}
                            selected={selected}
                            value={author}
                          >
                            {author}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card py-3 px-3">
                  <label className="form-label white">Image</label>
                  {blogImage.preview ? (
                    <div className="fileinput text-center">
                      <div className="image-preview">
                        {blogImage.preview && (
                          <Fragment>
                            <div
                              className="post-image"
                              style={{
                                backgroundImage: `url(${blogImage.preview})`,
                              }}
                            />
                            <i
                              onClick={removePicturePreview}
                              className="material-icons cursor-pointer"
                            >
                              x
                            </i>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="thumbnail" onClick={handleClick}>
                      <input
                        className="file"
                        type="file"
                        name="image"
                        multiple
                        onChange={handleImageChange}
                        ref={hiddenFileInput}
                        accept="image/*"
                      />
                      <button
                        type="button"
                        className="btn-simple btn btn-warning"
                      >
                        Select image
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-12">
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold white">SEO</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-12">
                        <label className="form-label">Title</label>
                        <div id="editor1">
                          <textarea
                            name="seoTitle"
                            defaultValue={blogObj.seoTitle}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Title"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Keyword</label>
                        <div id="editor2">
                          <textarea
                            name="seoKeywords"
                            defaultValue={blogObj.seoKeywords}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Keywords"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">Description</label>
                        <div id="editor3">
                          <textarea
                            name="seoDescription"
                            defaultValue={blogObj.seoDescription}
                            onChange={handleInputChanges}
                            className="form-control"
                            placeholder="SEO Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12">
              <div className="d-flex py-3 justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold white">Contents</h6>
                <div className="button-section">
                  <button type="button" onClick={() => addMoreContents()}>
                    Add
                  </button>
                </div>
              </div>
              {contents.map((ele, index) => (
                <div
                  className="row align-items-center position_relative"
                  key={ele._id}
                >
                  <BlogSection
                    {...ele}
                    index={index}
                    handleSectionContent={handleField}
                    deleteContentSection={deleteContentSection}
                    handleRichTextField={handleRichTextField}
                    handleContentImageField={handleContentImageField}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EditBlog
