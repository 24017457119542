import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'

import Layout from '../components/layout'
import {
  setCurrentUserHandler,
  adminLogoutHandler,
  fetchAsyncPermissions,
} from '../application/slices/auth.slice'
import setAuthToken from '../utils/setAuthToken'

import Home from './home'
import Dashboard from './dashboard'
import AddCategory from './category/add'
import EditCategory from './category/edit'
import ListCategory from './category/list'
import ProductList from './products/list'
import ServiceList from './services/list'
import ProductDetails from './products/details'
import ProductEdit from './products/edit'
import ProductAdd from './products/add'
import ServiceAdd from './services/add'
import Customers from './customers'
import OrderList from './orders/list'
import OrderDetails from './orders/details'
import CouponsList from './coupons/list'
import CouponsEdit from './coupons/edit'
import CouponsAdd from './coupons/add'
import Expenses from './accounts/expenses'
import Invoices from './accounts/invoices'
import OfferList from './offers/list'
import AddOffer from './offers/add'
import Contacts from './contacts'
import BlogLists from './blogs/list'
import AddBlog from './blogs/add'
import EditBlog from './blogs/edit'
import AllForums from './forums/list'
import AddForum from './forums/add'
import Forumedit from './forums/edit'
import EditService from './services/edit'
import Leads from './leads'
import Vendors from './vendors/list'
import AddVendor from './vendors/add'
import EditVendor from './vendors/edit'
import VendorDetails from './vendors/details'
import AllShops from './shops/list'
import AddShop from './shops/add'
import EditShop from './shops/edit'
import UserProfile from './profile'

const Routes = withRouter(({ location }) => {
  const isLoginPage = location.pathname === '/'
  //check for token
  if (
    typeof window !== 'undefined' &&
    localStorage !== undefined &&
    localStorage.admin_pet_token
  ) {
    const dispatch = useDispatch()
    // set the header auth
    setAuthToken(localStorage.admin_pet_token)
    const decoded = jwt_decode(localStorage.admin_pet_token)
    const { user = {} } = decoded
    // set user is authenticated
    dispatch(setCurrentUserHandler(user), 'currentUser')
    dispatch(fetchAsyncPermissions(), 'fetchAsyncPermissions')
    //check for expired token
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      dispatch(adminLogoutHandler(), 'logout')
      window.location.href = '/'
    }
  }

  return (
    <Layout isLoginPage={isLoginPage} pathName={location.pathname}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/categories" component={ListCategory} />
        <Route exact path="/products" component={ProductList} />
        <Route exact path="/services" component={ServiceList} />
        <Route exact path="/shops" component={AllShops} />
        <Route exact path="/vendors" component={Vendors} />
        <Route exact path="/customers" component={Customers} />
        <Route exact path="/orders" component={OrderList} />
        <Route exact path="/coupons" component={CouponsList} />
        <Route exact path="/blogs" component={BlogLists} />
        <Route exact path="/expenses" component={Expenses} />
        <Route exact path="/invoices" component={Invoices} />
        <Route exact path="/offers" component={OfferList} />
        <Route exact path="/contacts" component={Contacts} />
        <Route exact path="/leads" component={Leads} />
        <Route exact path="/forums" component={AllForums} />

        <Route exact path="/blog/add" component={AddBlog} />
        <Route exact path="/forum/add" component={AddForum} />
        <Route exact path="/product/add" component={ProductAdd} />
        <Route exact path="/service/add" component={ServiceAdd} />
        <Route exact path="/vendor/add" component={AddVendor} />
        <Route exact path="/category/add" component={AddCategory} />
        <Route exact path="/coupon/add" component={CouponsAdd} />
        <Route exact path="/offers/add" component={AddOffer} />
        <Route exact path="/shop/add" component={AddShop} />
        <Route exact path="/profile" component={UserProfile} />

        <Route path="/category/edit/:id" component={EditCategory} />
        <Route path="/product/edit/:id" component={ProductEdit} />
        <Route path="/product-detail/:id" component={ProductDetails} />
        <Route path="/service/edit/:id" component={EditService} />
        <Route path="/order/:id" component={OrderDetails} />
        <Route path="/coupon/edit/:id" component={CouponsEdit} />
        <Route path="/forum/edit/:id" component={Forumedit} />
        <Route path="/blog/edit/:id" component={EditBlog} />
        <Route path="/vendor/edit/:id" component={EditVendor} />
        <Route path="/shop/edit/:id" component={EditShop} />
        <Route path="/vendor-details/:id" component={VendorDetails} />
      </Switch>
    </Layout>
  )
})

export default Routes
