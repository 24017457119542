/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createShop } from '../../../application/slices/shop.slice'
import BreadCrumbs from '../../../components/breadcrumbs'
import RichTextEditor from '../../../components/richTextEditor'
import '../shops.scss'

const AddShop = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const hiddenFileInput = useRef(null)
  const [shop, setShop] = useState({
    name: '',
    slug: '',
    description: '',
    status: 'active',
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
    content: '',
    officalEmail: '',
  })
  const [shopImage, setShopImage] = useState({
    preview: '',
    file: '',
  })
  const [isDisabled, setIsDisabled] = useState(false)

  const handleInputChanges = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    let shopObject = { ...shop }

    if (name === 'name') {
      const titleSlug = value
        ? value.toLowerCase().replace(/&'/g, '').split(' ').join('-')
        : ''

      shopObject.name = value
      shopObject.slug = titleSlug
    } else {
      shopObject = { ...shopObject, [name]: value }
    }

    setShop(shopObject)
  }

  const handleStatus = (event) => {
    shop.status === 'inactive'
      ? setShop({ ...shop, [event.target.name]: 'active' })
      : setShop({ ...shop, [event.target.name]: 'inactive' })
  }

  const AddShopData = async (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }
    setIsDisabled(true)
    const { name, description } = shop
    if (!name || !description) {
      alert('Empty Field in adding data')
    } else {
      let shopDetail = { ...shop }
      shopDetail.image = shopImage.file
      dispatch(createShop(shopDetail, 'createShop')).then((data) => {
        setIsDisabled(false)
        if (data.error) {
          alert('Shop Already Exist')
        } else {
          history.push('/shops')
        }
      })
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const files = event.target.files
    if (files.length) {
      let singleImage = files[0]
      if (singleImage.size < 5242880) {
        setShopImage({
          preview: URL.createObjectURL(singleImage),
          file: singleImage,
        })
      } else {
        alert(`${singleImage.name} is greater than 5MB`)
      }
      // this line right below will reset the
      // input field so if you removed it you can re-add the same file
      event.target.value = ''
    }
  }

  const closeprofilePicturePreview = (event) => {
    event.preventDefault()
    setShopImage({
      preview: '',
      file: null,
    })
  }

  const handleRichContents = (pageContent) => {
    setShop({ ...shop, content: pageContent })
  }

  return (
    <div className="body d-flex py-3">
      <section className="border border-light">
        <div className="container-xxl">
          <BreadCrumbs
            selectedTabName="Shops"
            selectedTabUrl="shops"
            activePage="New"
          />
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0 white">Add Shop</h3>
                <button
                  onClick={AddShopData}
                  className="btn btn-primary py-2 px-5 text-uppercase btn-set-task w-sm-100"
                  disabled={isDisabled}
                >
                  {isDisabled ? 'Sending...' : 'Save'}
                </button>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-8">
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Basic Details</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        defaultValue={shop.name}
                        onChange={handleInputChanges}
                        className="form-control"
                        placeholder="Shop Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Url</label>
                      <input
                        type="text"
                        name="slug"
                        defaultValue={shop.slug}
                        onChange={handleInputChanges}
                        className="form-control"
                        placeholder="Shop Url"
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Official Email</label>
                      <input
                        type="text"
                        name="officalEmail"
                        defaultValue={shop.officalEmail}
                        onChange={handleInputChanges}
                        className="form-control"
                        placeholder="Shop Official Email"
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Description</label>
                      <div id="editor">
                        <textarea
                          name="description"
                          defaultValue={shop.description}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="Shop Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">SEO</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-12">
                      <label className="form-label">Title</label>
                      <div id="editor1">
                        <textarea
                          name="seoTitle"
                          defaultValue={shop.seoTitle}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Keyword</label>
                      <div id="editor2">
                        <textarea
                          name="seoKeywords"
                          defaultValue={shop.seoKeywords}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Keywords"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Description</label>
                      <div id="editor3">
                        <textarea
                          name="seoDescription"
                          defaultValue={shop.seoDescription}
                          onChange={handleInputChanges}
                          className="form-control"
                          placeholder="SEO Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-lg-top">
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
                    <h6 className="m-0 fw-bold white">Status</h6>
                  </div>
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        defaultChecked={shop.status === 'active'}
                        onClick={handleStatus}
                      />
                      <label className="form-check-label">Active</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        defaultChecked={shop.status === 'inactive'}
                        onClick={handleStatus}
                      />

                      <label className="form-check-label">In Active</label>
                    </div>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold white">Image</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 align-items-center justify-content-center">
                      <div className="col-md-12">
                        <div className="fileinput">
                          <div className="shop-preview">
                            {shopImage.preview && (
                              <Fragment>
                                <div
                                  className="shop-image"
                                  style={{
                                    backgroundImage: `url(${shopImage.preview})`,
                                  }}
                                />
                                <i
                                  onClick={closeprofilePicturePreview}
                                  className="material-icons cursor-pointer"
                                >
                                  x
                                </i>
                              </Fragment>
                            )}
                          </div>
                          <small className="d-block text-muted mb-2">
                            Only portrait or square images, 2M max and 2000px
                            max-height.
                          </small>
                          <div
                            className="thumbnail text-center"
                            onClick={handleClick}
                          >
                            <input
                              className="file"
                              type="file"
                              name="image"
                              multiple
                              onChange={handleChange}
                              ref={hiddenFileInput}
                              accept="image/*"
                            />
                            <button
                              type="button"
                              className="btn-simple btn btn-warning"
                            >
                              Select image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold white">Page Content</h6>
                </div>
                <div className="card-body">
                  <RichTextEditor handleRichContents={handleRichContents} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AddShop
